import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import cx from 'classnames';
import { Column } from 'react-table';

import { IUserScenariosTableData } from './UserScenariosTable.interfaces';

const SCENARIO_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

const SCENARIOS_TABLE_COLUMNS: ReadonlyArray<Column<IUserScenariosTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Title</div>,
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Description</div>,
  },
  {
    accessor: 'endDate',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>End date</div>,
  },
  {
    accessor: 'isTestPhase',
    Cell: ({ value }) => (
      <div className="c-scenario-status">
        <div
          className={cx('c-scenario__status', {
            'c-scenario__status--active': value,
            'c-scenario__status--not-active': !value,
          })}
        />
        <span>{value ? 'Notify test group' : 'Notify user'}</span>
      </div>
    ),
    Header: () => <div>Mode</div>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <div className="c-scenario-status">
        <div
          className={cx('c-scenario__status', {
            'c-scenario__status--active': value === 'ACTIVE',
            'c-scenario__status--not-active': value === 'INACTIVE',
          })}
        />
        <span>{value === 'ACTIVE' ? 'Running' : 'Not running'}</span>
      </div>
    ),
    Header: () => <div>Status</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update scenario" onClick={() => onClick(id)} />,
  },
];

export { SCENARIOS_TABLE_COLUMNS, SCENARIO_STATUS_TYPE };
