import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_PROJECT_USERS } from './useGetProjectUsers.graphql';
import {
  IGetPaginatedProjectUsersQueryGraphQLResponse,
  IGetPaginatedProjectUsersQueryVariables,
  IHandleFetchMorePaginatedProjectUsersParams,
  IHandleGetPaginatedProjectUsersParams,
  IUseGetPaginatedProjectUsers,
  IUseGetPaginatedProjectUsersOptions,
} from './useGetProjectUsers.interfaces';

const useGetPaginatedProjectUsers = (options?: IUseGetPaginatedProjectUsersOptions): IUseGetPaginatedProjectUsers => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedProjectUsersOptions);

  const [executeGetPaginatedProjectUsers, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetPaginatedProjectUsersQueryGraphQLResponse,
    IGetPaginatedProjectUsersQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ user: { byProject } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          users: byProject,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_PROJECT_USERS,
    token,
  });

  const handleFetchMorePaginatedProjectUsers = ({
    after,
    filter,
    first,
    projectId,
    status,
    shouldReturnUnassignedOnly = false,
  }: IHandleFetchMorePaginatedProjectUsersParams): Promise<ApolloQueryResult<IGetPaginatedProjectUsersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            user: { byProject: newlyFetchedUserConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedUserConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          user: { byProject: previouslyFetchedUserConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedUserConnection;

        return {
          ...previousQueryResult,
          user: {
            ...previouslyFetchedUserConnection,
            byProject: {
              ...newlyFetchedUserConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
        status,
        shouldReturnUnassignedOnly,
      },
    });
  };

  const handleGetPaginatedProjectUsers = async ({
    after,
    filter,
    first,
    projectId,
    status,
    shouldReturnUnassignedOnly = false,
  }: IHandleGetPaginatedProjectUsersParams) => {
    await executeGetPaginatedProjectUsers({
      variables: {
        after,
        filter,
        first,
        projectId,
        status,
        shouldReturnUnassignedOnly,
      },
    });
  };

  return {
    handleFetchMorePaginatedProjectUsers,
    handleGetPaginatedProjectUsers,
    isLoading,
  };
};

export { useGetPaginatedProjectUsers };
