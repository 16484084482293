import { useContext, useEffect, useState } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { SidebarNavigation, NavigationItemProps } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useAdminSiteUrls, useSchedulerSiteUrls } from 'hooks';
import { useRouter } from 'next/router';

import { IDashboardItemsList, PageTemplateProps } from './PageTemplate.interfaces';

import { PageLayout } from '../..';



const PageTemplate = ({
  activePage,
  activeSubPage,
  description,
  isLoading,
  pageTitle,
  children,
}: PageTemplateProps) => {

  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { isDomainReady } = useDomain();
  const { project } = useContext(CachingEntitiesContext);

  const { getDashboardUrl } = useAdminSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_PRODUCT_LOCAL_PORT,
  });

  const { getBaseUrl } = useSchedulerSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  const [dashboardLink, setDashboardLink] = useState<string>('');
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [projectId, setProjectId] = useState<string>('');
  const [dashboardItems, setDashboardItems] = useState<IDashboardItemsList[]>([]);


  useEffect(() => {

    if (!(baseUrl && projectId)) return;



    const cardItems: IDashboardItemsList[] = [
      {
        id: 'scenarios',
        description: 'Project scenarios page',
        iconId: 'id_filter_icon',
        title: 'Scenarios',
        subItems: [
          {
            id: 'users',
            description: 'Manage user scenario information',
            href: `${baseUrl}/project/${projectId}/scheduler/scenarios/users`,
            iconId: 'id_user_icon',
            title: 'Users',
          },
          {
            id: 'invitations',
            description: 'Manage invitation scenario information',
            href: `${baseUrl}/project/${projectId}/scheduler/scenarios/invitations`,
            iconId: 'id_invite_icon',
            title: 'Invitations',
          },

        ],
      },
      {
        id: 'simulator',
        description: 'Simulate scenarios',
        href: `${baseUrl}/project/${projectId}/scheduler/simulator`,
        iconId: 'id_simulation_icon',
        title: 'Simulator',
        subItems: [],
      },
      {
        id: 'test-mode',
        description: 'Test mode',
        href: `${baseUrl}/project/${projectId}/scheduler/test-mode`,
        iconId: 'id_user_flow_icon',
        title: 'Test mode',
        subItems: [],
      },
    ];

    setDashboardItems(cardItems);
  }, [projectId, baseUrl]);

  useEffect(() => {
    if (!(isDomainReady && project)) return;
    const dashboardUrl = getDashboardUrl();
    if (!dashboardUrl) return;

    const { id, organisation: { key: organisationKey } } = project;

    setDashboardLink(`${dashboardUrl}/${organisationKey}/${id}`);
  }, [getDashboardUrl, isDomainReady, project]);

  useEffect(() => {
    if (!isDomainReady) return;
    const schedulerBaseUrl = getBaseUrl();
    if (!schedulerBaseUrl) return;

    setBaseUrl(schedulerBaseUrl);
  }, [getBaseUrl, isDomainReady]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(queryProjectId as string);

  }, [queryProjectId]);

  return (
    <PageLayout
      additionalClassNames="c-table-page-template"
      isPreloaderVisible={isLoading}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >

      <SidebarNavigation
        activePage={activePage}
        activeSubPage={activeSubPage}
        dashboardLink={dashboardLink}
        navigationItems={dashboardItems.map((item) => {
          const { iconId: itemIcon, title: itemTitle, id, href, subItems } = item;
          return {
            iconId: itemIcon,
            label: itemTitle,
            id,
            href,
            subItems: subItems ? subItems.map((subItem) => {
              const { iconId: subIconId, title: subTitle, id: subId, href: subHref } = subItem;
              return {
                iconId: subIconId,
                label: subTitle,
                id: subId,
                href: subHref,
              };
            }): undefined
          } as NavigationItemProps;
        })}
      >
        <>
          {children}
        </>

      </SidebarNavigation>
    </PageLayout>
  );
};

export { PageTemplate };
