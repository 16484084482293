import { gql } from '@apollo/client';

const DELETE_SCENARIO_MUTATION = gql`
  mutation DeleteScenario($scenarioId: Int!) {
    scenarioMutations {
      deleteScenario(id: $scenarioId)
    }
  }
`;

export { DELETE_SCENARIO_MUTATION };
