import { scenarioTemplatesTabConstants } from './ScenarioTemplatesTab.constants';
import { IScenarioSidebarCurrentTemplates, IScenarioSidebarSearchTemplates } from './ScenarioTemplatesTab.interfaces';
import { countType } from './ScenarioTemplatesTab.types';

const { templateMinPercentage, templateTotalPercentage } = scenarioTemplatesTabConstants;

/**
 * Recalculating the percentage of each template
 */
const handleSetTemplatePercentage = (
  templateList: IScenarioSidebarCurrentTemplates[],
  selectedTemplateId: number,
  count: number,
  percentageCountType: countType,
) => {
  const updatedTemplates = templateList.map((template) => {
    const { id, percentage } = template;

    if (id !== selectedTemplateId) {
      return template;
    }

    let updatedPercentage: number;
    switch (percentageCountType) {
      case 'event':
        updatedPercentage = (count > templateTotalPercentage ? templateTotalPercentage : count) || count;
        break;
      case 'decrease':
        updatedPercentage = percentage - count > templateMinPercentage ? percentage - count : templateMinPercentage;
        break;
      case 'increase':
        updatedPercentage = percentage + count < templateTotalPercentage ? percentage + count : templateTotalPercentage;
        break;
    }

    return {
      ...template,
      percentage: updatedPercentage,
    };
  });

  return updatedTemplates;
};

/**
 * Updating the percentage of each template when the list updated to allocate the remaining percentage
 * Use this method when converting the list to the API object
 */
const handleUpdateTemplatesListToServerSideObject = (templateList: IScenarioSidebarSearchTemplates[]) => {
  let remainingPercentage = Number(templateTotalPercentage);
  const copiedTemplates = templateList.map((template, index) => {
    if (index !== templateList.length - 1) {
      remainingPercentage = remainingPercentage - Math.round(templateTotalPercentage / templateList.length);
    }

    return {
      percentage: index === templateList.length - 1 ? remainingPercentage : Math.round(templateTotalPercentage / templateList.length),
      templateId: template.id,
    };
  });

  return copiedTemplates;
};

/**
 * Updating the percentage of each template when the list updated
 * Use this method when converting the list for the UI
 */
const handleUpdateTemplatesList = (templateList: IScenarioSidebarSearchTemplates[]) => {
  let remainingPercentageList = Number(templateTotalPercentage);
  const updatedTemplatesList = templateList.map((template, index) => {
    if (index !== templateList.length - 1) {
      remainingPercentageList = remainingPercentageList - Math.round(templateTotalPercentage / templateList.length);
    }

    return {
      id: template.id,
      percentage: index === templateList.length - 1 ? remainingPercentageList : Math.round(templateTotalPercentage / templateList.length),
      title: template.title,
    };
  });

  return updatedTemplatesList;
};

export { handleSetTemplatePercentage, handleUpdateTemplatesList, handleUpdateTemplatesListToServerSideObject };
