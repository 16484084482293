import { gql } from '@apollo/client';

const DELETE_CONDITION_MUTATION = gql`
  mutation DeleteCondition($conditionId: Int!) {
    conditions {
      delete(conditionId: $conditionId)
    }
  }
`;

export { DELETE_CONDITION_MUTATION };
