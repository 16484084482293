import React from 'react';

import cx from 'classnames';

import { ToggleButtonProps } from './ToggleButton.interfaces';

function ToggleButton({ additionalClassNames, isChecked, id, labelText, name, onChange, value }: ToggleButtonProps) {
  return (
    <div className="c-toggle-button">
      <input
        checked={isChecked}
        className={cx(additionalClassNames, 'c-toggle-button__input')}
        id={String(id)}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
      />
      <label className="c-toggle-button__label" htmlFor={String(id)}>
        {labelText}
      </label>
    </div>
  );
}

export { ToggleButton };
