import React, { ChangeEvent, useEffect, useState } from 'react';

import { Input, Label, Tooltip } from '@netfront/ui-library';

import { convertDaysAndHoursToSeconds, convertSecondsToDaysAndHours } from './ConditionDelayInput.helpers';
import { ConditionDelayInputProps } from './ConditionDelayInput.interfaces';

const ConditionDelayInput = ({
  id,
  initialValue = 0,
  labelText,
  maxNumber,
  minNumber,
  name,
  onChange,
  tooltipText = '',
}: ConditionDelayInputProps) => {
  const [hours, setHours] = useState<number>(0);
  const [days, setDays] = useState<number>(0);

  const handleDaysUpdate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value } } = event;
    setDays(Number(value));

    onChange({name, value: convertDaysAndHoursToSeconds(Number(value), hours)});

  };

  const handleHoursUpdate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value } } = event;
    setHours(Number(value));

    onChange({name, value: convertDaysAndHoursToSeconds(days, Number(value))});
  };

  useEffect(() => {
    const { days: initialDays, hours: initialHours} = convertSecondsToDaysAndHours(initialValue);

    setDays(initialDays);
    setHours(initialHours);
  }, [initialValue]);

  return (
    <section className="c-condition-delay">
      <Label forId={`id_${id}_days_delay`} labelText={labelText} />

      <div className="c-condition-delay__inputs">
        <div className="c-condition-delay__input-wrapper">
          <Input
            additionalClassNames="c-condition-delay__input"
            id={`id_${id}_days_delay`}
            labelText="Days"
            maxNumber={maxNumber}
            minNumber={minNumber}
            name={`hours_${id}_delay`}
            type="number"
            value={days}
            isLabelHidden
            onChange={handleDaysUpdate}
          />
          <span>Days</span>
        </div>

        <div className="c-condition-delay__input-wrapper">
          <Input
            additionalClassNames="c-condition-delay__input"
            id={`id_${id}_hours_delay`}
            labelText="Hours"
            maxNumber={maxNumber}
            minNumber={minNumber}
            name={`hours_${id}_delay`}
            type="number"
            value={hours}
            isLabelHidden
            onChange={handleHoursUpdate}
          />
          <span>Hours</span>
        </div>
      </div>

      {tooltipText && (
        <Tooltip
          additionalClassNames="c-condition-delay__tooltip"
          bubbleTheme="dark"
          iconId="id_tooltip_icon"
          placement="left"
          text={tooltipText}
        />
      )}
    </section>
  );
};

export { ConditionDelayInput };
