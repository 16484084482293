import React, { ChangeEvent } from 'react';

import { RadioButtonGroup, Spacing, Tooltip } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';

import { ConditionFormUserSessionTypeViewProps } from './ConditionFormUserSessionTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormUserSessionTypeView = ({ currentCondition, onUpdateConditionInput, onUpdateConditionDelayInput }: ConditionFormUserSessionTypeViewProps) => {
  const { comparator, delay } = currentCondition ?? {};

  const { conditionMetOptions, conditionDelayMinNumber } = scenarioConditionTabConstants;

  const handleComparatorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    onUpdateConditionInput(event);
    if (value === 'NOT_EXISTS') onUpdateConditionDelayInput({name: 'delay', value: 0});
  };

  return (
    <div className="c-condition-form-user-activated-type-view">
      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <RadioButtonGroup
            additionalClassNames="c-condition-form-view__radio-group"
            items={conditionMetOptions}
            name="comparator"
            selectedValue={comparator}
            title="Condition met*"
            onChange={handleComparatorChange}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Did the user trigger the condition during the specified delay?"
          />
        </section>
      </Spacing>

      {comparator && comparator === 'EXISTS' && (
        <Spacing size="large">
          <ConditionDelayInput
            id="user_activated_type"
            initialValue={delay ?? 0}
            labelText="Condition delay"
            minNumber={conditionDelayMinNumber}
            name="delay"
            tooltipText="The schedule will execute using the configuration below"
            onChange={onUpdateConditionDelayInput}
          />
        </Spacing>
      )}

    </div>
  );
};

export { ConditionFormUserSessionTypeView };
