import { gql } from '@apollo/client';

const GET_SIMULATORS_QUERY = gql`
  query GetSimulators(
    $after: String = null
    $date: DateTime!
    $filter: String = null
    $first: Int = null
    $projectId: String!
    $scenarioId: Int = null
  ) {
    predictionQueries {
      predict(after: $after, date: $date, first: $first, filter: $filter, projectId: $projectId, scenarioId: $scenarioId) {
        edges {
          cursor
          node {
            template {
              id
              title
              type
            }
            user {
              email
              firstname
              id
              lastname
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { GET_SIMULATORS_QUERY };
