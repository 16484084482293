import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  Alert,
  ButtonIconOnly,
  Dialog,
  Icon,
  InformationBox,
  Label,
  Preloader,
  SearchWithButtonList,
  SidebarButtons,
  Spacing,
  Spinner,
  ToggleSwitch,
  Tooltip,
} from '@netfront/ui-library';
import cx from 'classnames';
import capitalize from 'lodash/capitalize';
import { useDebouncedCallback } from 'use-debounce';

import { scenarioTemplatesTabConstants } from './ScenarioTemplatesTab.constants';
import {
  handleSetTemplatePercentage,
  handleUpdateTemplatesList,
  handleUpdateTemplatesListToServerSideObject,
} from './ScenarioTemplatesTab.helpers';
import {
  IScenarioSidebarSearchTemplates,
  IScenarioSidebarCurrentTemplates,
  ScenarioTemplatesTabProps,
} from './ScenarioTemplatesTab.interfaces';

import { useGetCurrentTemplates, useGetPaginatedTemplates, useSetTemplates, useGetTemplate, useToast } from '../../../../hooks';
import { IDBTemplate } from '../../../../interfaces';
import { InputCounter } from '../../../InputCounter';

const ScenarioTemplatesTab = ({ projectId, scenarioId, onClose }: ScenarioTemplatesTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const {
    abToggleAlertVisibilityInSeconds,
    counterStepValue,
    debounceQueryWaitTimeInMilliseconds,
    inputCounterMaxValue,
    inputCounterMinValue,
    templateTotalPercentage,
  } = scenarioTemplatesTabConstants;

  const [isAbTesting, setIsAbTesting] = useState<boolean>(false);
  const [isAbToggleAlertVisible, setIsAbToggleAlertVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isTemplatePreviewDialogOpen, setIsTemplatePreviewDialogOpen] = useState<boolean>(false);
  const [scenarioTemplates, setScenarioTemplates] = useState<IScenarioSidebarCurrentTemplates[]>();
  const [scenarioTemplateContent, setScenarioTemplateContent] = useState<IDBTemplate['content']>();
  const [scenarioTemplatesTotalPercentage, setTemplatesTotalPercentage] = useState<number>(templateTotalPercentage);
  const [selectedTemplate, setSelectedTemplate] = useState<IScenarioSidebarCurrentTemplates>();
  const [templates, setTemplates] = useState<IScenarioSidebarSearchTemplates[]>([]);

  const { handleGetPaginatedTemplates: executeGetPaginatedTemplates, isLoading: isGetPaginatedTemplatesLoading } = useGetPaginatedTemplates(
    {
      onCompleted: ({ templatesConnection: { edges } }) => {
        const allTemplates = edges.map(({ node }) => node);

        const searchTemplates = allTemplates.map((template) => {
          const { id, name } = template;

          return {
            id,
            title: name,
          };
        });

        setTemplates(searchTemplates);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  const { handleGetCurrentTemplates, isLoading: isGetCurrentTemplatesLoading } = useGetCurrentTemplates({
    onCompleted: ({ templateConnection: templatesData }) => {
      const allScenarioTemplates = templatesData.map((template) => {
        const {
          percentage,
          template: { id, title, type },
        } = template;

        const templateType = type.split('_').length > 1 ? capitalize(type.split('_').join(' ').toLocaleLowerCase()) : type;

        return {
          id,
          percentage,
          title: `${title} (${templateType})`,
        };
      });

      setScenarioTemplates(allScenarioTemplates);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetTemplate: executeGetTemplate, isLoading: isGetTemplateLoading } = useGetTemplate({
    onCompleted: ({ templateConnection: template }) => {
      const { content } = template;

      setIsTemplatePreviewDialogOpen(true);
      setScenarioTemplateContent(content);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSetTemplates: executeSetTemplates, isLoading: isSetTemplatesLoading } = useSetTemplates({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      handleToastSuccess({
        message: 'Current templates updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleChangeInputCount = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = event;

    if (!scenarioTemplates) {
      return;
    }

    setScenarioTemplates(handleSetTemplatePercentage(scenarioTemplates, Number(id), Number(value), 'event'));
  };

  const handleClickRemoveTemplateButton = (template: IScenarioSidebarCurrentTemplates) => {
    setIsDeleteDialogOpen(true);
    setSelectedTemplate(template);
  };

  const handleDebounceGetTemplates = useDebouncedCallback((inputText) => {
    executeGetPaginatedTemplates({
      projectId,
      first: 10,
      filter: inputText,
    });
  }, Number(debounceQueryWaitTimeInMilliseconds));

  const handleDecreaseCount = (id: number) => {
    if (!scenarioTemplates) {
      return;
    }

    setScenarioTemplates(handleSetTemplatePercentage(scenarioTemplates, Number(id), counterStepValue, 'decrease'));
  };

  const handleGetTemplate = (template: IScenarioSidebarCurrentTemplates) => {
    setSelectedTemplate(template);

    executeGetTemplate({
      templateId: template.id,
    });
  };

  const handleIncreaseCount = (id: number) => {
    if (!scenarioTemplates) {
      return;
    }

    setScenarioTemplates(handleSetTemplatePercentage(scenarioTemplates, Number(id), counterStepValue, 'increase'));
  };

  const handleRecommendedSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value.length <= 2) {
      return;
    }

    handleDebounceGetTemplates(value);
  };

  const handleSetTemplates = (selectedScenarioTemplate: IScenarioSidebarSearchTemplates | null) => {
    if (!scenarioTemplates) {
      return;
    }

    if (!selectedScenarioTemplate) {
      return;
    }

    const updatedTemplates = [...scenarioTemplates, selectedScenarioTemplate];

    const updatedTemplatesList = handleUpdateTemplatesList(updatedTemplates);

    setIsAbTesting(updatedTemplatesList.length > 1);

    setScenarioTemplates(updatedTemplatesList);

    executeSetTemplates({
      scenarioId,
      templatesAllocation: handleUpdateTemplatesListToServerSideObject(updatedTemplates),
    });
  };

  const handleRemoveTemplate = () => {
    if (!scenarioTemplates) {
      return;
    }

    const updatedTemplates = scenarioTemplates.filter(({ id }) => id !== selectedTemplate?.id);

    const updatedTemplatesList = handleUpdateTemplatesList(updatedTemplates);

    setIsAbTesting(updatedTemplatesList.length > 1);

    executeSetTemplates({
      scenarioId,
      templatesAllocation: handleUpdateTemplatesListToServerSideObject(updatedTemplates),
    });

    setIsDeleteDialogOpen(false);

    if (updatedTemplatesList.length === 0) {
      return;
    }

    setScenarioTemplates(updatedTemplatesList);
  };

  const handleSaveTemplates = () => {
    if (!scenarioTemplates) {
      return;
    }

    const copiedTemplates = scenarioTemplates.map(({ id: templateId, percentage }) => {
      return {
        percentage,
        templateId,
      };
    });

    executeSetTemplates({
      scenarioId,
      templatesAllocation: copiedTemplates,
    });
  };

  const handleToggleABTesting = () => {
    setIsAbTesting(!isAbTesting);

    if (!scenarioTemplates) {
      return;
    }

    if (scenarioTemplates.length > 1) {
      setIsAbTesting(true);
      setIsAbToggleAlertVisible(true);

      return;
    }

    setIsAbTesting(!isAbTesting);
  };

  useEffect(() => {
    if (!scenarioId) {
      return;
    }

    handleGetCurrentTemplates({
      scenarioId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId]);

  useEffect(() => {
    if (!scenarioTemplates) {
      return;
    }

    setTemplatesTotalPercentage(
      scenarioTemplates.reduce((accumulator, { percentage }) => {
        return accumulator + percentage;
      }, 0),
    );

    setIsAbTesting(scenarioTemplates.length > 1);
  }, [scenarioTemplates]);

  useEffect(() => {
    if (!isAbToggleAlertVisible) {
      return;
    }

    setTimeout(() => {
      setIsAbToggleAlertVisible(false);
    }, abToggleAlertVisibilityInSeconds * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAbToggleAlertVisible]);

  const isLoading = isGetCurrentTemplatesLoading ?? isGetPaginatedTemplatesLoading ?? isSetTemplatesLoading;

  return (
    <div className="c-scenario-sidebar-templates-view">
      <Spinner isLoading={isGetTemplateLoading ?? false} />

      <Spacing size="2x-large">
        <ToggleSwitch
          additionalClassNames="c-scenario-sidebar-templates-view__ab-toggle"
          id="ab-test"
          isChecked={isAbTesting}
          labelText="A/B"
          tooltipPosition="end"
          tooltipText="Send variations of templates within this scenario"
          onChange={handleToggleABTesting}
        />

      </Spacing>

      <Spacing size="2x-large">
        <section className="c-scenario-sidebar-templates-view__search-section">
          <Tooltip
            additionalClassNames="c-scenario-sidebar-templates-view__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Search for existing templates within the project templates library"
          />

          <SearchWithButtonList
            isDisabled={!isAbTesting && scenarioTemplates?.length === 1}
            labelText="Copy existing template"
            listItems={templates}
            onChange={handleRecommendedSearchInput}
            onClick={handleSetTemplates}
          />
        </section>
      </Spacing>

      <Spacing size="2x-large">
        {isLoading ? (
          <Preloader isLoading={isLoading} />
        ) : (
          <section className="c-scenario-sidebar-templates-view__list-section">
            <Label forId="templates-list" labelText="Current templates" />

            {scenarioTemplates && scenarioTemplates.length !== 0 ? (
              <div>
                <ul className="c-scenario-sidebar-templates-view__templates-list" id="templates-list">
                  {scenarioTemplates.map((currentTemplate) => {
                    const { id, percentage, title } = currentTemplate;

                    return (
                      <li
                        key={`${id}-${title}`}
                        className={cx('c-scenario-sidebar-templates-view__templates-item', {
                          [`c-scenario-sidebar-templates-view__templates-item--one`]: scenarioTemplates.length === 1,
                        })}
                      >
                        <div>
                          <button
                            className="c-scenario-sidebar-templates-view__preview-button"
                            title="Preview template"
                            type="button"
                            onClick={() => handleGetTemplate(currentTemplate)}
                          >
                            <Icon id="id_eye_show_icon" />
                          </button>
                          <span className="c-scenario-sidebar-templates-view__templates__name">{title}</span>

                          <Dialog
                            isOpen={isTemplatePreviewDialogOpen}
                            title={`Preview template: ${String(selectedTemplate?.title)}`}
                            onCancel={() => setIsTemplatePreviewDialogOpen(false)}
                            onClose={() => setIsTemplatePreviewDialogOpen(false)}
                          >
                            {scenarioTemplateContent ? (
                              <div dangerouslySetInnerHTML={{ __html: scenarioTemplateContent }} />
                            ) : (
                              'No content available for the template'
                            )}
                          </Dialog>
                        </div>

                        <div className="c-scenario-sidebar-templates-view__counter">
                          {scenarioTemplates.length > 1 && (
                            <InputCounter
                              id={String(id)}
                              maxNumber={inputCounterMaxValue}
                              minNumber={inputCounterMinValue}
                              name="percentage"
                              value={percentage}
                              onChange={handleChangeInputCount}
                              onClickDecreaseCount={() => handleDecreaseCount(id)}
                              onClickIncreaseCount={() => handleIncreaseCount(id)}
                            />
                          )}

                          <ButtonIconOnly
                            additionalClassNames={String(id)}
                            iconId="id_bin_icon"
                            text="Remove template"
                            onClick={() => handleClickRemoveTemplateButton(currentTemplate)}
                          />

                          <Dialog
                            isOpen={isDeleteDialogOpen}
                            title={`Delete template: ${String(selectedTemplate?.title)}?`}
                            isNarrow
                            onCancel={() => setIsDeleteDialogOpen(false)}
                            onClose={() => setIsDeleteDialogOpen(false)}
                            onConfirm={handleRemoveTemplate}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <Spacing>
                  {scenarioTemplates.length > 1 && (
                    <div className="c-scenario-sidebar-templates-view__templates-item c-scenario-sidebar-templates-view__templates-item--total">
                      <span className="c-scenario-sidebar-templates-view__templates-item__title">{scenarioTemplates.length} titles</span>
                      <div className="c-scenario-sidebar-templates-view__templates-item__total">
                        <span>Total</span>
                        <span>{scenarioTemplatesTotalPercentage}%</span>
                      </div>
                    </div>
                  )}
                </Spacing>

                {isAbToggleAlertVisible && (
                  <Alert
                    iconId="id_warning_icon"
                    message="Please remove multiple templates before switching A/B testing off"
                    timeAlertIsVisibleInSeconds={abToggleAlertVisibilityInSeconds}
                  />
                )}
              </div>
            ) : (
              <InformationBox message="No templates attached for the scenario" />
            )}
          </section>
        )}
      </Spacing>

      <SidebarButtons
        onCancel={onClose}
        onSave={handleSaveTemplates}
      />
    </div>
  );
};

export { ScenarioTemplatesTab };
