import { gql } from '@apollo/client';

const SORT_CONDITION_GROUP_MUTATION = gql`
  mutation SortConditionGroup($conditionGroupId: Int!, $sort: Int!) {
    conditionGroups {
      sort(conditionGroupId: $conditionGroupId, sort: $sort) {
        conditions {
          createdAt
          delay
          description
          id
          scenario {
            title
          }
          scenarioId
          title
          updatedAt
          ... on ConditionDayGraphType {
            day
          }
          ... on ConditionHourGraphType {
            value
          }
          ... on ConditionSessionGraphType {
            comparator
          }
          ... on ConditionStepStartedGraphType {
            comparator
            stepId
          }
          ... on ConditionStepCompletedGraphType {
            comparator
            stepId
          }
          ... on ConditionUserActivatedGraphType {
            comparator
          }
          ... on ConditionUserLoginGraphType {
            comparator
          }
          ... on ConditionStepProgressionGraphType {
            comparator
            stepId
            percentage
            valueComparator
          }
        }
        id
        sort
        operator
      }
    }
  }
`;

export { SORT_CONDITION_GROUP_MUTATION };
