import React from 'react';

import { RadioButtonGroup, Spacing, Tooltip } from '@netfront/ui-library';

import { ConditionFormDayTypeViewProps } from './ConditionFormDayTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormDayTypeView = ({ currentCondition, onUpdateConditionInput }: ConditionFormDayTypeViewProps) => {
  const { day } = currentCondition ?? {};

  const { conditionDayOptions } = scenarioConditionTabConstants;

  return (
    <div className="c-condition-form-day-type-view">
      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <RadioButtonGroup
            additionalClassNames="c-condition-form-view__radio-group"
            items={conditionDayOptions}
            name="day"
            selectedValue={day}
            title="Condition day"
            onChange={onUpdateConditionInput}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Choose day of the week"
          />
        </section>
      </Spacing>
    </div>
  );
};

export { ConditionFormDayTypeView };
