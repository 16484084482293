import React, { useEffect, useState } from 'react';

import { Input, Spacing, Textarea } from '@netfront/ui-library';

import { IInvitationRemindersOnInputChange } from 'components/Sidebars';

import { InvitationReminderGeneralTabConstants } from './InvitationRemindersGeneralTab.constants';
import { InvitationRemindersGeneralTabProps } from './InvitationRemindersGeneralTab.interfaces';

import { IInvitationReminder } from '../../../../interfaces';

const InvitationRemindersGeneralTab = ({
  onInputChange,
  initialReminder,
  isLoading = false,
}: InvitationRemindersGeneralTabProps) => {
  const { descriptionCharacterMaxLength, titleCharacterMaxLength } = InvitationReminderGeneralTabConstants;

  const [currentReminder, setCurrentReminder] = useState<IInvitationReminder>({} as IInvitationReminder);

  const { title = '', description = ''} = currentReminder;

  const handleInputChange = ({target: { name, value}}: IInvitationRemindersOnInputChange) => {
    setCurrentReminder({
      ...currentReminder,
      [name]: value,
    });
    onInputChange({name: name as keyof IInvitationReminder, value})
  };

  useEffect(() => {
    setCurrentReminder(initialReminder ?? {} as IInvitationReminder);
  }, [initialReminder])

  return (
    <>
      <Spacing size="large">
        <Input
          id="title"
          isLoading={isLoading}
          labelText="Title"
          maxLength={titleCharacterMaxLength}
          name="title"
          type="text"
          value={title}
          isRequired
          onChange={handleInputChange}
        />
      </Spacing>

      <Spacing>
        <Textarea
          id="description"
          isLoading={isLoading}
          labelText="Description"
          maxLength={descriptionCharacterMaxLength}
          name="description"
          value={description}
          isRequired
          onChange={handleInputChange}
        />
      </Spacing>
    </>
  );
};

export { InvitationRemindersGeneralTab };
