import { gql } from '@apollo/client';

const GET_TEMPLATE_QUERY = gql`
  query GetTemplate($templateId: Int!) {
    project {
      getTemplate(id: $templateId) {
        content
        id
        name
      }
    }
  }
`;

export { GET_TEMPLATE_QUERY };
