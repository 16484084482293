import { gql } from '@apollo/client';

import { SCENARIO_FRAGMENT } from '../../../fragments';

const ADD_SCENARIO_MUTATION = gql`
  mutation AddScenario(
    $projectId: String!
    $title: String!
    $isTestPhase: Boolean!
    $description: String
    $overrideReceiver: String
    $shouldIncludeConditions: Boolean!
  ) {
    scenarioMutations {
      addScenario(projectId: $projectId, title: $title, isTestPhase: $isTestPhase, description: $description, overrideReceiver: $overrideReceiver) {
        ...scenarioFragment
      }
    }
  }

  ${SCENARIO_FRAGMENT}
`;

export { ADD_SCENARIO_MUTATION };
