import { ApolloQueryResult } from '@apollo/client';

import { useCapuchinLazyQuery } from '../capuchin/useCapuchinLazyQuery';

import { GET_TEST_USERS_QUERY } from './useGetTestUsers.graphql';
import {
  IGetPaginatedTestUsersQueryGraphQLResponse,
  IGetPaginatedTestUsersQueryVariables,
  IHandleFetchMorePaginatedTestUsersParams,
  IHandleGetPaginatedTestUsersParams,
  IUseGetPaginatedTestUsers,
  IUseGetPaginatedTestUsersOptions,
} from './useGetTestUsers.interfaces';

const useGetPaginatedTestUsers = (options?: IUseGetPaginatedTestUsersOptions): IUseGetPaginatedTestUsers => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedTestUsersOptions);

  const [executeGetPaginatedTestUsers, { fetchMore, loading: isLoading }] = useCapuchinLazyQuery<
    IGetPaginatedTestUsersQueryGraphQLResponse,
    IGetPaginatedTestUsersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectTestUserQueries: { get: testUsersConnection },
        } = data;

        onCompleted({
          testUsersConnection,
        });
      },
      onError,
    },
    query: query ?? GET_TEST_USERS_QUERY,
    token,
  });

  const handleFetchMorePaginatedTestUsers = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedTestUsersParams): Promise<ApolloQueryResult<IGetPaginatedTestUsersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          projectTestUserQueries: { get: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          projectTestUserQueries: { get: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          projectTestUserQueries: {
            ...previouslyFetchedScenarioConnection,
            get: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleGetPaginatedTestUsers = ({ after, filter, first, projectId }: IHandleGetPaginatedTestUsersParams) => {
    void executeGetPaginatedTestUsers({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedTestUsers,
    handleGetPaginatedTestUsers,
    isLoading,
  };
};

export { useGetPaginatedTestUsers };
