import React from 'react';

import { Input, Label, Spacing, Select } from '@netfront/ui-library';

import { ScheduleTabYearlyProps } from './ScheduleTabYearly.interfaces';

import { scenarioScheduleTabConstants } from '../../ScenarioScheduleTab.constants';

const ScheduleTabYearly = ({ schedule, onUpdateScenarioInput }: ScheduleTabYearlyProps) => {
  const { recurrenceMaxNumberDay, recurrenceMinNumber, scheduleMonthOptions, scheduleDayCountOfTheMonthTypeMap } =
    scenarioScheduleTabConstants;

  return (
    <div className="c-schedule-tab-yearly">
      <Label forId="monthly" labelText="Recurrence pattern" />

      <Spacing size="2x-large">
        <div className="c-scenario-sidebar-schedule-view__occurrence-input-container">
          <span>Recur every</span>
          <Input
            additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
            id="occurEvery"
            labelText="occurEvery"
            minNumber={recurrenceMinNumber}
            name="occurEvery"
            type="number"
            value={schedule?.occurEvery}
            isLabelHidden
            isRequired
            onChange={onUpdateScenarioInput}
          />
          <span>year(s)</span>
        </div>
      </Spacing>

      <div className="c-scenario-sidebar-schedule-view__update-date-input-container">
        <span>On</span>

        <Select
          additionalClassNames="c-scenario-sidebar-schedule-view__update-date-select"
          id="month"
          labelText="Month"
          name="month"
          options={scheduleMonthOptions}
          value={schedule?.month}
          isLabelHidden
          isRequired
          onChange={onUpdateScenarioInput}
        />

        <Input
          additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
          id="day"
          labelText="Day"
          maxNumber={scheduleDayCountOfTheMonthTypeMap[schedule?.day ?? recurrenceMaxNumberDay]}
          minNumber={recurrenceMinNumber}
          name="day"
          type="number"
          value={schedule?.day}
          isLabelHidden
          isRequired
          onChange={onUpdateScenarioInput}
        />
      </div>
    </div>
  );
};

export { ScheduleTabYearly };
