import React from 'react';

import cx from 'classnames';

import { ToggleButton } from '../ToggleButtons/ToggleButton';

import { ToggleButtonsProps } from './ToggleButtons.interfaces';

function ToggleButtons({ additionalClassNames, name, selectedValue, items, onChange }: ToggleButtonsProps) {
  return (
    <div className={cx(additionalClassNames, 'c-toggle-buttons')}>
      {items.map(({ id, labelText, value }, index) => {
        const isChecked = value === selectedValue;

        const timeInMilliSeconds = new Date().getUTCMilliseconds();

        return (
          <ToggleButton
            key={`${id}-${index}-${timeInMilliSeconds}`}
            additionalClassNames={cx({
              ['c-toggle-button__input--first']: index === 0,
              ['c-toggle-button__input--last']: index + 1 === items.length,
            })}
            id={`${id}-${index}-${timeInMilliSeconds}`}
            isChecked={isChecked}
            labelText={labelText}
            name={name}
            value={value}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}

export { ToggleButtons };
