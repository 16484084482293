import React from 'react';

import { Spacing } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';

import { ConditionFormUserLastLoginTypeViewProps } from './ConditionFormUserLastLoginTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormUserLastLoginTypeView = ({ currentCondition, onUpdateConditionDelayInput }: ConditionFormUserLastLoginTypeViewProps) => {
  const { delay } = currentCondition ?? {};

  const { conditionDelayMinNumber } = scenarioConditionTabConstants;

  return (
    <div className="c-condition-form-user-last-login-type-view">
      <Spacing size="large">
        <ConditionDelayInput
          id="user_last_login_type"
          initialValue={delay ?? 0}
          labelText="Condition delay"
          minNumber={conditionDelayMinNumber}
          name="delay"
          tooltipText="The schedule will execute using the configuration below"
          onChange={onUpdateConditionDelayInput}
        />
      </Spacing>
    </div>
  );
};

export { ConditionFormUserLastLoginTypeView };
