import { gql } from '@apollo/client';

const GET_PAGINATED_TEMPLATES_QUERY = gql`
  query GetPaginatedTemplates($after: String = null, $filter: String = null, $first: Int = null, $projectId: String!) {
    project {
      search(after: $after, first: $first, filter: $filter, projectId: $projectId) {
        edges {
          cursor
          node {
            content
            id
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { GET_PAGINATED_TEMPLATES_QUERY };
