import { gql } from '@apollo/client';

const UPDATE_INVITATION_REMINDER = gql`
  mutation updateInvitationReminder(
    $request: InvitationReminderInputGraphType!
    $id: Int!
  ) {
    invitationReminders {
      update(id: $id, request: $request) {
        id
        delay
        description
        end
        start
        templateId
        title
        groupId
      }
    }
  }
`;

export { UPDATE_INVITATION_REMINDER };
