// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { UPDATE_SCHEDULE_DETAILS_MUTATION } from './useUpdateScheduleDetails.graphql';
import {
  IUpdateScheduleDetailsMutationGraphQLResponse,
  IUpdateScheduleDetailsMutationVariables,
  IHandleUpdateScheduleDetailsParams,
  IUseUpdateScheduleDetailsOptions,
  IUseUpdateScheduleDetails,
} from './useUpdateScheduleDetails.interfaces';

const useUpdateScheduleDetails = (options?: IUseUpdateScheduleDetailsOptions): IUseUpdateScheduleDetails => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateScheduleDetailsOptions);

  const [executeUpdateSchedule, { loading: isLoading }] = useCapuchinMutation<
    IUpdateScheduleDetailsMutationGraphQLResponse,
    IUpdateScheduleDetailsMutationVariables
  >({
    mutation: mutation ?? UPDATE_SCHEDULE_DETAILS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioMutations: { updateDetails: scenario },
        } = data;

        onCompleted(scenario);
      },
      onError,
    },
    token,
  });

  const handleUpdateScheduleDetails = ({
    endDate,
    maxRecurrence,
    scenarioId,
    shouldIncludeConditions,
    startDate,
  }: IHandleUpdateScheduleDetailsParams) => {
    void executeUpdateSchedule({
      variables: {
        endDate,
        maxRecurrence,
        scenarioId,
        shouldIncludeConditions,
        startDate,
      },
    });
  };

  return {
    handleUpdateScheduleDetails,
    isLoading,
  };
};

export { useUpdateScheduleDetails };
