

const HOUR_TO_SECONDS = 3600;

const DAYS_TO_SECONDS = 86400;


const conditionDelayInputConstants = Object.freeze({
  secondsInHour: HOUR_TO_SECONDS,
  secondsInDay: DAYS_TO_SECONDS,
});

export { conditionDelayInputConstants };
