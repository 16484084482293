import { gql } from '@apollo/client';

const UPDATE_SCHEDULE_MUTATION = gql`
  mutation UpdateSchedule(
    $scenarioId: Int!
    $daily: ScheduleDailyInputGraphType = null
    $weekly: ScheduleWeeklyInputGraphType = null
    $monthly: ScheduleMonthlyInputGraphType = null
    $yearly: ScheduleYearlyInputGraphType = null
  ) {
    schedule {
      updateSchedule(id: $scenarioId, daily: $daily, weekly: $weekly, monthly: $monthly, yearly: $yearly) {
        ... on ScheduleDailyGraphType {
          occurEvery
        }
        ... on ScheduleWeeklyGraphType {
          occurEvery
          recurrenceDays
        }
        ... on ScheduleMonthlyGraphType {
          day
          occurEvery
        }
        ... on ScheduleYearlyGraphType {
          day
          month
          occurEvery
        }
      }
    }
  }
`;

export { UPDATE_SCHEDULE_MUTATION };
