import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_BY_ID_QUERY } from './useGetUserById.graphql';
import {
  IGetUserByIdQueryGraphQLResponse,
  IGetUserByIdQueryVariables,
  IHandleGetUserByIdParams,
  IUseGetUserById,
  IUseGetUserByIdOptions,
} from './useGetUserById.interfaces';

const useGetUserById = (options?: IUseGetUserByIdOptions): IUseGetUserById => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetUserByIdOptions);

  const [executeGetUserById, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetUserByIdQueryGraphQLResponse,
    IGetUserByIdQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: {
            getById: {
              credential: { phoneNumber },
            },
          },
        } = data;

        onCompleted({
          phoneNumber,
        });
      },
      onError,
    },
    query: query ?? GET_USER_BY_ID_QUERY,
    token,
  });

  const handleGetUserById = ({ userId, projectId }: IHandleGetUserByIdParams) => {
    void executeGetUserById({
      variables: {
        userId,
        projectId,
      },
    });
  };

  return {
    handleGetUserById,
    isLoading,
  };
};

export { useGetUserById };
