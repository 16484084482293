import { useCapuchinLazyQuery } from '../capuchin/useCapuchinLazyQuery';

import { SEARCH_CONDITIONS_QUERY } from './useSearchConditions.graphql';
import {
  IGetPaginatedConditionsQueryGraphQLResponse,
  IGetPaginatedConditionsQueryVariables,
  IHandleGetPaginatedConditionsParams,
  IUseGetPaginatedConditions,
  IUseGetPaginatedConditionsOptions,
} from './useSearchConditions.interfaces';

const useGetPaginatedConditions = (options?: IUseGetPaginatedConditionsOptions): IUseGetPaginatedConditions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedConditionsOptions);

  const [executeGetPaginatedConditions, { loading: isLoading }] = useCapuchinLazyQuery<
  IGetPaginatedConditionsQueryGraphQLResponse,
  IGetPaginatedConditionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioQueries: { searchConditions: conditionConnection },
        } = data;

        onCompleted({
          conditionConnection,
        });
      },
      onError,
    },
    query: query ?? SEARCH_CONDITIONS_QUERY,
    token,
  });

  const handleGetPaginatedConditions = ({ after, filter, first, projectId, scenarioId }: IHandleGetPaginatedConditionsParams) => {
    void executeGetPaginatedConditions({
      variables: {
        after,
        filter,
        first,
        projectId,
        scenarioId,
      },
    });
  };

  return {
    handleGetPaginatedConditions,
    isLoading,
  };
};

export { useGetPaginatedConditions };
