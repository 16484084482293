import { gql } from '@apollo/client';

const ADD_TEST_USER_MUTATION = gql`
  mutation AddTestUser($projectId: String!, $userId: Int!) {
    projectTestUserMutations {
      addUser(projectId: $projectId, userId: $userId) {
        email
        firstname
        id
        lastname
      }
    }
  }
`;

export { ADD_TEST_USER_MUTATION };
