// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { ADD_CONDITION_GROUP_MUTATION } from './useAddConditionGroup.graphql';
import {
  IAddConditionGroupMutationGraphQLResponse,
  IAddConditionGroupMutationVariables,
  IHandleAddConditionGroupParams,
  IUseAddConditionGroupOptions,
  IUseAddConditionGroup,
} from './useAddConditionGroup.interfaces';

const useAddConditionGroup = (options?: IUseAddConditionGroupOptions): IUseAddConditionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddConditionGroupOptions);

  const [executeAddConditionGroup, { loading: isLoading }] = useCapuchinMutation<
    IAddConditionGroupMutationGraphQLResponse,
    IAddConditionGroupMutationVariables
  >({
    mutation: mutation ?? ADD_CONDITION_GROUP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditionGroups: { add: conditionGroupConnection },
        } = data;

        onCompleted(conditionGroupConnection);
      },
      onError,
    },
    token,
  });

  const handleAddConditionGroup = async ({ scenarioId, operator }: IHandleAddConditionGroupParams) => {
    await executeAddConditionGroup({
      variables: {
        scenarioId,
        operator,
      },
    });
  };

  return {
    handleAddConditionGroup,
    isLoading,
  };
};

export { useAddConditionGroup };
