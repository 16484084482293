import { gql } from '@apollo/client';

const DELETE_INVITATION_REMINDER = gql`
  mutation deleteInvitaionReminder($id: Int!) {
    invitationReminders {
      remove(id: $id)
    }
  }
`;

export { DELETE_INVITATION_REMINDER };
