import { ApolloQueryResult } from '@apollo/client';

import { useCapuchinLazyQuery } from '../capuchin/useCapuchinLazyQuery';

import { GET_SIMULATORS_QUERY } from './useGetSimulators.graphql';
import {
  IGetPaginatedSimulatorsQueryGraphQLResponse,
  IGetPaginatedSimulatorsQueryVariables,
  IHandleFetchMorePaginatedSimulatorsParams,
  IHandleGetPaginatedSimulatorsParams,
  IUseGetPaginatedSimulators,
  IUseGetPaginatedSimulatorsOptions,
} from './useGetSimulators.interfaces';

const useGetPaginatedSimulators = (options?: IUseGetPaginatedSimulatorsOptions): IUseGetPaginatedSimulators => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedSimulatorsOptions);

  const [executeGetPaginatedSimulators, { fetchMore, loading: isLoading }] = useCapuchinLazyQuery<
    IGetPaginatedSimulatorsQueryGraphQLResponse,
    IGetPaginatedSimulatorsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          predictionQueries: { predict: simulatorsConnection },
        } = data;

        onCompleted({
          simulatorsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_SIMULATORS_QUERY,
    token,
  });

  const handleFetchMorePaginatedSimulators = ({
    after,
    date,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedSimulatorsParams): Promise<ApolloQueryResult<IGetPaginatedSimulatorsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          predictionQueries: { predict: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          predictionQueries: { predict: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          predictionQueries: {
            ...previouslyFetchedScenarioConnection,
            predict: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        date,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleGetPaginatedSimulators = ({ after, date, filter, first, projectId, scenarioId }: IHandleGetPaginatedSimulatorsParams) => {
    void executeGetPaginatedSimulators({
      variables: {
        after,
        date,
        filter,
        first,
        projectId,
        scenarioId,
      },
    });
  };

  return {
    handleFetchMorePaginatedSimulators,
    handleGetPaginatedSimulators,
    isLoading,
  };
};

export { useGetPaginatedSimulators };
