import React, { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  Dialog,
  Input,
  ISearchWithButtonListItem,
  Preloader,
  SearchWithButtonList,
  SidebarButtons,
  Spacing,
  Spinner,
  Tooltip,
} from '@netfront/ui-library';
import noop from 'lodash/noop';
import { useDebouncedCallback } from 'use-debounce';

import { testGroupGeneralTabConstants } from './TestGroupGeneralTab.constants';
import { TestGroupGeneralTabProps } from './TestGroupGeneralTab.interfaces';

import { useAddTestUser, useDeleteTestUser, useGetUserById, useGetPaginatedProjectUsers, useToast } from '../../../../hooks';
import { IDBTestUser } from '../../../../interfaces';

const TestGroupGeneralTab = ({
  isAddNewTestUserVisible,
  projectId,
  testUser,
  onClose,
  onCreated,
  onDeleted,
}: TestGroupGeneralTabProps) => {
  const { handleToastError } = useToast();

  const { debounceQueryWaitTimeInMilliseconds } = testGroupGeneralTabConstants;

  const [projectUsersToAttach, setProjectUsersToAttach] = useState<ISearchWithButtonListItem[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');

  const { email = '', id: testUserId = 0, firstname: firstName = '', lastname: lastName = '' } = testUser ?? {};

  const { handleAddTestUser, isLoading: isAddTestUserLoading = false } = useAddTestUser({
    onCompleted: (data: IDBTestUser) => {
      onCreated(data);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteTestUser: executeDeleteTestUser, isLoading: isDeleteTestUserLoading = false } = useDeleteTestUser({
    onCompleted: () => {
      if (!testUser) {
        return;
      }

      onDeleted(projectId, testUser.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetUserById: executeGetUserById, isLoading: isGetUserByIdLoading = false } = useGetUserById({
    onCompleted: ({ phoneNumber }) => {
      setUserPhoneNumber(phoneNumber);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetPaginatedProjectUsers, isLoading: isGetPaginatedProjectUsersLoading = false } = useGetPaginatedProjectUsers({
    onCompleted: ({ users: { edges } }) => {
      const projectUsers = edges.map(({ node }) => node);

      setProjectUsersToAttach(
        projectUsers.map(({ firstName: userFirstName, lastName: userLastName, id }) => ({
          id,
          title: `${userFirstName} ${userLastName}`,
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDebounceGetPaginatedConditionsQuery = useDebouncedCallback((inputText) => {
    void handleGetPaginatedProjectUsers({
      projectId,
      filter: inputText,
      status: 'ACTIVE'
    });
  }, Number(debounceQueryWaitTimeInMilliseconds));

  const handleAddNewTestUser = (selectedTestUser: ISearchWithButtonListItem | null) => {
    if (!selectedTestUser) {
      return;
    }

    handleAddTestUser({
      projectId,
      userId: selectedTestUser.id,
    });
  };

  const handleDeleteTestUser = () => {
    if (!testUserId) {
      return;
    }

    executeDeleteTestUser({
      userId: testUserId,
      projectId,
    });
  };

  const handleRecommendedSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value.length <= 2) {
      return;
    }

    handleDebounceGetPaginatedConditionsQuery(value);
  };

  useEffect(() => {
    if (!testUser) {
      return;
    }

    executeGetUserById({
      userId: testUserId,
      projectId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testUser]);

  const isLoading = isAddTestUserLoading || isDeleteTestUserLoading;

  const isPreloading = isGetUserByIdLoading || isGetPaginatedProjectUsersLoading;

  return (
    <div className="c-test-user-sidebar-general-view">
      <Spinner isLoading={isLoading} />

      <Preloader isLoading={isPreloading} />

      {!isAddNewTestUserVisible &&
        (!isPreloading ? (
          <>
            <Spacing size="large">
              <Input id="firstName" labelText="First name" name="firstName" type="text" value={firstName} isDisabled onChange={noop} />
            </Spacing>

            <Spacing size="large">
              <Input id="lastName" labelText="Last name" name="lastName" type="text" value={lastName} isDisabled onChange={noop} />
            </Spacing>

            <Spacing size="large">
              <Input
                id="phoneNumber"
                labelText="Phone number"
                name="phoneNumber"
                type="text"
                value={userPhoneNumber}
                isDisabled
                onChange={noop}
              />
            </Spacing>

            <Spacing size="large">
              <Input id="email" labelText="Email" name="email" type="text" value={email} isDisabled onChange={noop} />
            </Spacing>

            <Dialog
              isOpen={isDeleteDialogOpen}
              title={`Delete TestUser: ${firstName} ${lastName}?`}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onClose={() => setIsDeleteDialogOpen(false)}
              onConfirm={handleDeleteTestUser}
            />
            <SidebarButtons
              onCancel={onClose}
              onDelete={() => setIsDeleteDialogOpen(true)}
            />

          </>
        ) : (
          <Preloader isLoading={isPreloading} />
        ))}

      {isAddNewTestUserVisible && (
        <>
          <Spacing size="2x-large">
            <section className="c-test-group-sidebar-condition-view__search-section">
              <Tooltip
                additionalClassNames="c-test-group-sidebar-condition-view__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Add team member to test group to test scenarios before sending to the project users"
              />

              <SearchWithButtonList
                labelText="Add team member"
                listItems={projectUsersToAttach}
                onChange={handleRecommendedSearchInput}
                onClick={handleAddNewTestUser}
              />
            </section>
          </Spacing>
          <SidebarButtons
            onCancel={onClose}
          />
        </>
      )}
    </div>
  );
};

export { TestGroupGeneralTab };
