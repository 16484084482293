import { gql } from '@apollo/client';

const GET_CONDITION_GROUPS_QUERY = gql`
  query GetConditionGroups($scenarioId: Int!) {
    scenarioQueries {
      getConditionGroups(scenarioId: $scenarioId) {
        id
        operator
        sort
        conditions {
          createdAt
          delay
          description
          id
          title
          updatedAt
          ... on ConditionDayGraphType {
            day
          }
          ... on ConditionHourGraphType {
            value
          }
          ... on ConditionSessionGraphType {
            comparator
          }
          ... on ConditionStepStartedGraphType {
            comparator
            stepId
          }
          ... on ConditionStepCompletedGraphType {
            comparator
            stepId
          }
          ... on ConditionUserActivatedGraphType {
            comparator
          }
          ... on ConditionUserLoginGraphType {
            comparator
          }
          ... on ConditionStepProgressionGraphType {
            comparator
            stepId
            percentage
            valueComparator
          }
          ... on ConditionUserGroupGraphType {
            groupId
          }
        }
      }
    }
  }
`;

export { GET_CONDITION_GROUPS_QUERY };
