import React, { ChangeEvent } from 'react';

import { InformationBox, RadioButtonGroup, Select, Spacing, Tooltip } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';

import { ConditionFormStepCompletedTypeViewProps } from './ConditionFormStepCompletedTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';
import { getConditionStepSelectOptions } from '../../ConditionFormView.helpers';

const ConditionFormStepCompletedTypeView = ({
  conditionStepName,
  currentCondition,
  userFlowSteps,
  onUpdateConditionInput,
  onUpdateConditionStepSelect,
  onUpdateConditionDelayInput,
}: ConditionFormStepCompletedTypeViewProps) => {
  const { comparator, delay } = currentCondition ?? {};

  const { conditionDelayMinNumber, conditionMetOptions } = scenarioConditionTabConstants;

  const handleComparatorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    onUpdateConditionInput(event);
    if (value === 'NOT_EXISTS') onUpdateConditionDelayInput({name: 'delay', value: 0});
  };

  return (
    <div className="c-condition-form-step-completed-type-view">
      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Select
            id="condition-step"
            labelText="Time point"
            name="conditionStep"
            options={getConditionStepSelectOptions(userFlowSteps)}
            value={conditionStepName}
            isRequired
            onChange={onUpdateConditionStepSelect}
          />

          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Time point when the condition will be triggered"
          />
        </section>
      </Spacing>

      {userFlowSteps.length === 0 && (
        <Spacing size="x-large">
          <InformationBox iconId="id_warning_icon" message="Please create a userflow for the project" />
        </Spacing>
      )}

      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <RadioButtonGroup
            additionalClassNames="c-condition-form-view__radio-group"
            items={conditionMetOptions}
            name="comparator"
            selectedValue={comparator}
            title="Condition met*"
            onChange={handleComparatorChange}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Did the user trigger the condition during the specified delay?"
          />
        </section>
      </Spacing>

      {comparator && comparator === 'EXISTS' && (
        <Spacing size="large">
          <ConditionDelayInput
            id="user_activated_type"
            initialValue={delay ?? 0}
            labelText="Condition delay"
            minNumber={conditionDelayMinNumber}
            name="delay"
            tooltipText="The schedule will execute using the configuration below"
            onChange={onUpdateConditionDelayInput}
          />
        </Spacing>
      )}
    </div>
  );
};

export { ConditionFormStepCompletedTypeView };
