import React, { useEffect, useState } from 'react';

import { Button, Dialog, DatePicker } from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { TableTemplate } from 'components/Shared/TableTemplate';

import { simulatorsTableConstants } from './SimulatorsTable.constants';
import { getSimulatorsTableData } from './SimulatorsTable.helpers';
import { ISimulatorsTableData } from './SimulatorsTable.interfaces';

import { useGetPaginatedSimulators, useGetTemplate, useToast } from '../../../../../../hooks';
import { IDBSimulator, IDBTemplate } from '../../../../../../interfaces';
import { SIMULATOR_PAGE_CONSTANTS } from '../../SimulatorPage.constants';

const SimulatorsTable = () => {
  const { query } = useRouter();
  const { handleToastError } = useToast();

  const { SIMULATORS_TABLE_COLUMNS, maxDateAdditionalYear } = simulatorsTableConstants;

  const maxDate = String(Number(new Date().getFullYear()) + maxDateAdditionalYear);

  const { projectId } = query;
  const { defaultPageSize } = SIMULATOR_PAGE_CONSTANTS;

  const [allSimulators, setAllSimulators] = useState<IDBSimulator[]>();
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isTemplatePreviewDialogOpen, setIsTemplatePreviewDialogOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [simulatorTemplateContent, setSimulatorTemplateContent] = useState<IDBTemplate['content']>();
  const [simulatorTemplateTitle, setSimulatorTemplateTitle] = useState<IDBTemplate['name']>('');
  const [simulatorsTableData, setSimulatorsTableData] = useState<ISimulatorsTableData[]>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalSimulators, setTotalSimulators] = useState<number>(0);
  const [simulatorDate, setSimulatorDate] = useState<Date>(new Date());

  const {
    handleFetchMorePaginatedSimulators,
    handleGetPaginatedSimulators,
    isLoading: isGetPaginatedSimulatorsLoading = false,
  } = useGetPaginatedSimulators({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ simulatorsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const Simulators = edges.map(({ node }) => node);

      setAllSimulators(Simulators);
      setIsLoadMoreDisabled(Simulators.length >= totalCount || totalCount <= pageSize);
      setTotalSimulators(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetTemplate: executeGetTemplate, isLoading: isGetTemplateLoading = false } = useGetTemplate({
    onCompleted: ({ templateConnection: template }) => {
      const { content, name } = template;

      setSimulatorTemplateTitle(name);
      setIsTemplatePreviewDialogOpen(true);
      setSimulatorTemplateContent(content);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleChangeSimulatorDate = (date?: Date| null) => {
    if (!date) return;
    setSimulatorDate(date);
  };


  const handleFilterSearch = (value: string) => {
    handleGetPaginatedSimulators({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
      date: simulatorDate,
    });

    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleGetTemplate = (templateId: IDBSimulator['template']['id']) => {
    executeGetTemplate({
      templateId,
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedSimulators({
      first: pageSize,
      projectId: String(projectId),
      date: simulatorDate,
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allSimulators) {
      return;
    }

    setSimulatorsTableData(
      getSimulatorsTableData({
        simulators: allSimulators,
        onPreviewButtonClick: (template) => {
          handleGetTemplate(template.id);
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSimulators]);

  const isLoading = isGetPaginatedSimulatorsLoading || isGetTemplateLoading;

  return (
    <>
      <TableTemplate<ISimulatorsTableData>
        childrenEnd={
          <div className="c-simulators-table__dates">
            <DatePicker hasPadding={false} id="id_date_picker" labelText="Select date" maxDate={maxDate ? new Date(maxDate) : undefined } selectedDate={new Date()} isLabelHidden onSingleDateChangeHandler={handleChangeSimulatorDate} />
            <Button size="xs" text="Submit" type="button" variant="primary-inverse" onClick={() => handleFilterSearch(filter ?? '')}/>
          </div>
        }
        columns={SIMULATORS_TABLE_COLUMNS}
        data={simulatorsTableData ?? []}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedSimulators({
            filter,
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            date: simulatorDate,
          });
        }}
        handleSearch={handleFilterSearch}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle={''}
        tableStats={
          {
            label: pluralize('simulator', totalSimulators),
            totalCount: totalSimulators,
          }}
        tableType="simulators"
        title="Simulators"
        totalItems={totalSimulators}
      />

      <Dialog
        isOpen={isTemplatePreviewDialogOpen}
        title={`Preview template: ${simulatorTemplateTitle}`}
        onCancel={() => setIsTemplatePreviewDialogOpen(false)}
        onClose={() => setIsTemplatePreviewDialogOpen(false)}
      >
        {simulatorTemplateContent ? (
          <div dangerouslySetInnerHTML={{ __html: simulatorTemplateContent }} />
        ) : (
          'No content available for the template'
        )}
      </Dialog>
    </>
  );
};

export { SimulatorsTable };
