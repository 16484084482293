import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import pluralize from 'pluralize';
import { Column } from 'react-table';

import { convertSecondsToDaysAndHours } from 'components/ConditionDelayInput';

import { IInvitationRemindersTableData } from './InvitationRemindersTable.interfaces';

const INVITATION_SCENARIO_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

const INVITATION_SCENARIOS_TABLE_COLUMNS: ReadonlyArray<Column<IInvitationRemindersTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Title</div>,
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Description</div>,
  },
  {
    accessor: 'start',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Start</div>,
  },
  {
    accessor: 'end',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>End</div>,
  },
  {
    accessor: 'delay',
    Cell: ({ value }) => {
      const { days, hours} = convertSecondsToDaysAndHours(value);
      return (<div>{days} {pluralize('day', days)} {hours} {pluralize('hour', hours)}</div>);
    },
    Header: () => <div>Delay</div>,
  },

  // {
  //   accessor: 'status',
  //   Cell: ({ value }) => (
  //     <div className="c-scenario-status">
  //       <div
  //         className={cx('c-scenario__status', {
  //           'c-scenario__status--active': value === 'ACTIVE',
  //           'c-scenario__status--not-active': value === 'INACTIVE',
  //         })}
  //       />
  //       <span>{value === 'ACTIVE' ? 'Running' : 'Not running'}</span>
  //     </div>
  //   ),
  //   Header: () => <div>Status</div>,
  // },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update invitation reminder" onClick={() => onClick(id)} />,
    Header: () => <div className="h-hide-visually">Actions</div>,
  },
];

export { INVITATION_SCENARIOS_TABLE_COLUMNS, INVITATION_SCENARIO_STATUS_TYPE };
