// TODO: Move this to @netfront/capuchin-library

import { useCapuchinMutation } from '../capuchin';

import { SET_TEMPLATES_MUTATION } from './useSetTemplates.graphql';
import {
  ISetTemplatesMutationGraphQLResponse,
  ISetTemplatesMutationVariables,
  IHandleSetTemplatesParams,
  IUseSetTemplatesOptions,
  IUseSetTemplates,
} from './useSetTemplates.interfaces';

const useSetTemplates = (options?: IUseSetTemplatesOptions): IUseSetTemplates => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSetTemplatesOptions);

  const [executeSetTemplates, { loading: isLoading }] = useCapuchinMutation<
    ISetTemplatesMutationGraphQLResponse,
    ISetTemplatesMutationVariables
  >({
    mutation: mutation ?? SET_TEMPLATES_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioMutations: { setTemplates: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSetTemplates = ({ scenarioId, templatesAllocation }: IHandleSetTemplatesParams) => {
    void executeSetTemplates({
      variables: {
        scenarioId,
        templatesAllocation,
      },
    });
  };

  return {
    handleSetTemplates,
    isLoading,
  };
};

export { useSetTemplates };
