import { useContext, useEffect, useState } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { Pagination, TablePageTemplate as NetfrontTableTemplate, SideBarTabSet, IBreadcrumbItem, ITableRequiredParameters, PageContainer } from '@netfront/ui-library';
import { useAdminSiteUrls } from 'hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getInformationBoxMessageTableStats } from 'utils';

import { TableTemplateProps } from './TableTemplate.interfaces';

import { CachingEntitiesContext } from '../../../context';



const TableTemplate = <T extends ITableRequiredParameters>({
  bulkActions,
  bulkActionPlaceHolder,
  childrenEnd,
  childrenMiddle,
  columns,
  data,
  defaultActiveTabId = 'id_general_tab',
  handleAddNewClick,
  handleOnPageSizeChange,
  handleOnPaginate,
  handleSearch,
  isLoading,
  isPaginationDisabled = false,
  isSideBarOpen = false,
  pageSize = 10,
  pageTitle,
  searchPlaceholder = '',
  tabs = [],
  title,
  toggleIsSideBarOpen,
  totalItems = 0,
  hideSideBarButtons = true,
  tableType = '',
  onSaveButtonType = 'button',
  onSelectRows,
  size,
  iconId,
  avatarImageSrc,
  tableStats,
  informationBoxMessage = ''
}: TableTemplateProps<T>) => {

  const { isDomainReady } = useDomain();

  const { getDashboardUrl } = useAdminSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_PRODUCT_LOCAL_PORT,
  });

  const {
    query: { projectId },
  } = useRouter();

  const { cmsBaseUrl, project } = useContext(CachingEntitiesContext);

  const [organisationKey, setOrganisationKey] = useState<string>();
  const [projectName, setProjectName] = useState<string>();
  const [dashboardLink, setDashboardLink] = useState<string>('');

  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);

  useEffect(() => {
    if (!(dashboardLink && organisationKey && projectId)) {
      return;
    }

    setBreadcrumbItems([
      {
        key: '0',
        content: <Link href={dashboardLink}><a>Dashboard</a></Link>,
      },
      {
        key: '1',
        content: (
          <a href={`${String(cmsBaseUrl)}/dashboard/${String(organisationKey)}/${String(projectId)}/notifications`}>
            Notifications
          </a>
        ),
      },
      {
        key: '2',
        content: <span>Scheduler</span>,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardLink, organisationKey, projectId]);

  useEffect(() => {
    setOrganisationKey(project?.organisation.key);
    setProjectName(project?.name);
  }, [project]);

  useEffect(() => {
    if (!(isDomainReady && project && organisationKey)) return;
    const dashboardUrl = getDashboardUrl();
    if (!dashboardUrl) return;

    const { id } = project;

    setDashboardLink(`${dashboardUrl}/${organisationKey}/${id}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDashboardUrl, isDomainReady, project]);

  if (!projectName) return <></>;

  return (
    <PageContainer>
      <NetfrontTableTemplate<T>
        addNewOnClick={handleAddNewClick}
        avatarImageSrc={avatarImageSrc}
        breadcrumbItems={breadcrumbItems}
        bulkActionPlaceHolder={bulkActionPlaceHolder}
        bulkActions={bulkActions}
        childrenEnd={childrenEnd}
        childrenMiddle={childrenMiddle}
        columns={columns}
        data={data}
        emptyStateImageUrl="/images/empty-state.png"
        iconId={iconId}
        informationBoxMessage={getInformationBoxMessageTableStats({projectName, pageName : 'schedule notifications', tableStats, customMessage: informationBoxMessage })}
        isLoading={isLoading}
        pageTitle={pageTitle}
        searchPlaceholder={searchPlaceholder}
        size={size}
        tableType={tableType}
        title={title}
        onSearch={handleSearch}
        onSelectRows={onSelectRows}
      />

      {totalItems > pageSize && handleOnPaginate && handleOnPageSizeChange && (
        <Pagination
          isLoadMoreDisabled={isPaginationDisabled}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handleOnPaginate}
          onPageSizeChange={handleOnPageSizeChange}
        />
      )}
      {tabs.length > 0 && (
        <SideBarTabSet
          defaultActiveTabId={defaultActiveTabId}
          handleOpenCloseSideBar={toggleIsSideBarOpen ? toggleIsSideBarOpen : () => {return}}
          hideSideBarButtons={hideSideBarButtons}
          isSideBarOpen={isSideBarOpen}
          tabs={tabs}
          onSaveButtonType={onSaveButtonType}
        />
      )}
    </PageContainer>

  );
};

export { TableTemplate };
