import { gql } from '@apollo/client';

const SET_TEMPLATES_MUTATION = gql`
  mutation SetTemplates($scenarioId: Int!, $templatesAllocation: [TemplateInputGraphType!]!) {
    scenarioMutations {
      setTemplates(id: $scenarioId, templatesAllocation: $templatesAllocation)
    }
  }
`;

export { SET_TEMPLATES_MUTATION };
