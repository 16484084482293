import { gql } from '@apollo/client';

const DELETE_CONDITION_GROUP_MUTATION = gql`
  mutation DeleteConditionGroup($conditionGroupId: Int!) {
    conditionGroups {
      delete(conditionGroupId: $conditionGroupId)
    }
  }
`;

export { DELETE_CONDITION_GROUP_MUTATION };
