import React from 'react';

import { PageTemplate } from 'components/Shared';

import { UserScenariosTable } from './Tables';


const UserScenariosPage = () => {
  return (
    <>
      <PageTemplate
        activePage="scenarios"
        activeSubPage="users"
        description="Manage schedules"
        pageTitle="Scheduler management"
      >
        <UserScenariosTable/>
      </PageTemplate>
    </>
  );
};

export { UserScenariosPage };
