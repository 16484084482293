import { gql } from '@apollo/client';

const GET_USER_BY_ID_QUERY = gql`
  query GetUserById($userId: Int!, $projectId: String!) {
    user {
      getById(userId: $userId, projectId: $projectId) {
        credential {
          phoneNumber
        }
      }
    }
  }
`;

export { GET_USER_BY_ID_QUERY };
