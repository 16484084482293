import React from 'react';

import {
  Label,
  Spacing,
  DatePicker,
  Skeleton,
} from '@netfront/ui-library';


import { InvitationRemindersScheduleTabProps } from './InvitationRemindersScheduleTab.interfaces';


const InvitationRemindersScheduleTab = ({ initialReminder, onInputChange, isLoading = false }: InvitationRemindersScheduleTabProps) => {
  if (isLoading) return (
    <>
      <Skeleton additionalClassNames="c-label" width="10rem" />
      <Skeleton height="3rem" width="100%"/>
    </>
  );

  return (
    <>
      <Spacing size="large">
        <Label forId="schedule-date-selection" isLoading={isLoading} labelText="Date"/>

        <section className="c-scenario-sidebar-schedule-view__dates" id="schedule-date-selection">
          <DatePicker id="id_start_date_picker" isLoading={isLoading} labelText="Start" selectedDate={initialReminder?.start} onSingleDateChangeHandler={(date: Date | null) => onInputChange({ name: 'start', value: date})} />

          <DatePicker id="id_end_date_picker" isLoading={isLoading} labelText="End" selectedDate={initialReminder?.end} onSingleDateChangeHandler={(date: Date | null) => onInputChange({ name: 'end', value: date})} />
        </section>
      </Spacing>
    </>
  );
};

export { InvitationRemindersScheduleTab };
