import React, { useEffect, useState } from 'react';

import { ITab, SideBarTabSet } from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { TableTemplate } from 'components/Shared/TableTemplate';

import { TEST_USERS_TABLE_COLUMNS } from './TestUsersTable.constants';
import { getTestUsersTableData } from './TestUsersTable.helpers';
import { ITestUsersTableData } from './TestUsersTable.interfaces';

import { useGetPaginatedTestUsers, useToast } from '../../../../../../hooks';
import { IDBTestUser } from '../../../../../../interfaces';
import { TestGroupGeneralTab } from '../../../../../Tabs';
import { TEST_MODE_PAGE_CONSTANTS } from '../../TestModePage.constants';

const TestUsersTable = () => {
  const { query } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { projectId } = query;
  const { defaultPageSize } = TEST_MODE_PAGE_CONSTANTS;

  const [allTestUsers, setAllTestUsers] = useState<IDBTestUser[]>();
  const [filter, setFilter] = useState<string>();
  const [isAddNewTestUser, setIsAddNewTestUser] = useState<boolean>(false);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [testUsersTableData, setTestUsersTableData] = useState<ITestUsersTableData[]>();
  const [selectedTestUser, setSelectedTestUser] = useState<IDBTestUser>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalTestUsers, setTotalTestUsers] = useState<number>(0);

  const {
    handleFetchMorePaginatedTestUsers,
    handleGetPaginatedTestUsers,
    isLoading: isGetPaginatedTestUsersLoading = false,
  } = useGetPaginatedTestUsers({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ testUsersConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const testUsers = edges.map(({ node }) => node);

      setAllTestUsers(testUsers);
      setIsLoadMoreDisabled(testUsers.length >= totalCount || totalCount <= pageSize);
      setTotalTestUsers(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewTestUserClick = () => {
    setIsAddNewTestUser(true);
    setIsSideBarOpen(true);
  };

  const handleAddTestUser = (testUser: IDBTestUser) => {
    setAllTestUsers((currentState): IDBTestUser[] | undefined => {
      if (!currentState) {
        return;
      }

      return [...currentState, testUser];
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Test user added successfully',
    });
  };


  const handleDeletedTestUser = (_projectId: string, id: IDBTestUser['id']) => {
    setAllTestUsers((currentState): IDBTestUser[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.filter((testUser: IDBTestUser) => {
        return testUser.id !== id;
      });
    });

    handleSideBarClose();

    handleToastSuccess({
      message: 'Test user deleted successfully',
    });
  };

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedTestUsers({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsAddNewTestUser(false);
    setIsSideBarOpen(false);
    setSelectedTestUser(undefined);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedTestUsers({
      first: pageSize,
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allTestUsers) {
      return;
    }

    setTestUsersTableData(
      getTestUsersTableData({
        testUsers: allTestUsers,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedTestUser(allTestUsers.find(({ id: testUserId }) => id === testUserId));
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTestUsers]);

  const isLoading = isGetPaginatedTestUsersLoading;

  const addNewTestUserTabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <TestGroupGeneralTab
          isAddNewTestUserVisible={isAddNewTestUser}
          projectId={String(projectId)}
          testUser={!isAddNewTestUser ? selectedTestUser : undefined}
          onClose={handleSideBarClose}
          onCreated={handleAddTestUser}
          onDeleted={handleDeletedTestUser}
        />
      ),
    },
  ];

  return (
    <>
      <TableTemplate<ITestUsersTableData>
        columns={TEST_USERS_TABLE_COLUMNS}
        data={testUsersTableData ?? []}
        handleAddNewClick={handleAddNewTestUserClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedTestUsers({
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage="If any running scenario has been set to Test mode, only users configured in the list below will receive the notification"
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle={''}
        tableStats={
          {
            label: pluralize('test user', totalTestUsers),
            totalCount: totalTestUsers,
          }}
        tableType="test users"
        title="Test users"
        totalItems={totalTestUsers}
      />

      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        tabs={addNewTestUserTabs}
        hideSideBarButtons
      />
    </>
  );
};

export { TestUsersTable };
