import React from 'react';


import { PageTemplate } from 'components/Shared';

import { SimulatorsTable } from './Tables';

const SimulatorPage = () => {
  return (
    <>
      <PageTemplate
        activePage="simulator"
        description="Manage schedules"
        pageTitle="Scheduler management"
      >
        <SimulatorsTable/>
      </PageTemplate>
    </>
  );
};

export { SimulatorPage };
