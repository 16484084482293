import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import { IGeladaProject, useDomain, useGetGeladaProject } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useAdminSiteUrls, useToast } from 'hooks';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

export function CachingEntitiesProvider({ children }: CachingEntitiesContextProps) {
  const router = useRouter();
  const { projectId } = router.query;

  const { handleToastError } = useToast();

  const { isDomainReady } = useDomain();

  const { getBaseUrl } = useAdminSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_PRODUCT_LOCAL_PORT,
  });

  const [cmsBaseUrl, setCmsBaseUrl] = useState<string>('');
  const [project, setProject] = useState<IGeladaProject>();
  const [projectExpiry, setProjectExpiry] = useState<number>();

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('project');

      void handleGetGeladaProject({
        projectId: String(projectId),
        shouldIncludeProjectLogo: true,
        shouldIncludeProjectSettings: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    try {
      const projectStoredJSON = JSON.parse(String(window.localStorage.getItem('project')));
      if (!projectStoredJSON) {
        return;
      }
      if (!projectStoredJSON.value) {
        return;
      }
      setProject(JSON.parse(String(projectStoredJSON.value)) as IGeladaProject);
      setProjectExpiry(Number(projectStoredJSON.expiry));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      window.localStorage.removeItem('project');
    }
  }, []);

  useEffect(() => {
    if (!projectExpiry) {
      return;
    }

    if (new Date().getTime() < projectExpiry) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiry]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setCmsBaseUrl(getBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <CachingEntitiesContext.Provider
      value={{
        cmsBaseUrl: cmsBaseUrl,
        project,
      }}
    >
      {isGetGeladaProjectLoading ? <Spinner isLoading={true} /> : children}
    </CachingEntitiesContext.Provider>
  );
}
