import { useEffect, useRef, useState } from 'react';

import { Dialog, IOption, SideBarTabSet, Spinner } from '@netfront/ui-library';
import { useCreateInvitationReminder, useDeleteInvitationReminder, useGetInvitationReminder, useGetPaginatedGroups, useToast, useUpdateInvitationReminder } from 'hooks';
import { IInvitationReminder } from 'interfaces';

import { InvitationRemindersConditionTab, InvitationRemindersGeneralTab, InvitationRemindersScheduleTab, InvitationRemindersTemplatesTab } from 'components/Tabs';

import { InvitationRemindersOnInputChangeProps, InvitationRemindersSidebarProps } from './InvitationRemindersSidebar.interfaces';


const InvitationRemindersSidebar = ({
  handleAddReminder,
  handleDeletedReminder,
  handleSideBarClose,
  handleUpdatedReminder,
  isSideBarOpen = false,
  projectId,
  selectedReminderId,
}: InvitationRemindersSidebarProps) => {
  const { handleToastError } = useToast();

  const [currentReminder, setCurrentReminder] = useState<IInvitationReminder>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const invitationReminderRef = useRef<{ value: IInvitationReminder}>({ value : {} as IInvitationReminder});
  const [groupOptions, setGroupOptions] = useState<IOption[]>([]);


  const { handleGetInvitationReminder, isLoading: isGetInvitationReminderLoading = false } = useGetInvitationReminder({
    onCompleted: ({ invitationReminder }) => {
      const formattedInvitationReminder = {
        ...invitationReminder,
        end: invitationReminder.end ? new Date(invitationReminder.end): undefined,
        start: invitationReminder.start ? new Date(invitationReminder.start): undefined,
      };
      setCurrentReminder(formattedInvitationReminder);
      invitationReminderRef.current.value = formattedInvitationReminder;

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetPaginatedGroups } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges } }) => {

      const groups = edges.map(({ node }) => node);

      const groupsSelectOptions = groups.map(
        ({ id, name }): IOption => ({
          id,
          name,
          value: id,
        }),
      );

      setGroupOptions(groupsSelectOptions);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateInvitationReminder, isLoading: isCreateInvitationReminderLoading = false } = useCreateInvitationReminder({
    onCompleted: ({ invitationReminder }) => {
      handleAddReminder(invitationReminder);
      setCurrentReminder(undefined);
      invitationReminderRef.current.value = {} as IInvitationReminder;

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateInvitationReminder, isLoading: isUpdateInvitationReminderLoading = false } = useUpdateInvitationReminder({
    onCompleted: ({ invitationReminder }) => {
      handleUpdatedReminder(invitationReminder);
      setCurrentReminder(undefined);
      invitationReminderRef.current.value = {} as IInvitationReminder;
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteInvitationReminder, isLoading: isDeleteInvitationReminderLoading = false } = useDeleteInvitationReminder({
    onCompleted: () => {
      if (!selectedReminderId) {
        return;
      }

      handleDeletedReminder(selectedReminderId);
      setCurrentReminder(undefined);
      invitationReminderRef.current.value = {} as IInvitationReminder;
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const closeSidebar = () => {
    setCurrentReminder(undefined);
    invitationReminderRef.current.value = {} as IInvitationReminder;
    handleSideBarClose();
  }

  const handleInputChange = ({ name, value }: InvitationRemindersOnInputChangeProps) => {
    invitationReminderRef.current.value = {
      ...invitationReminderRef.current.value,
      [name]: value,
    };
  };


  const handleSubmit = () => {
    const { id, title, description, delay, end, start, templateId, groupId} = invitationReminderRef.current.value;

    if (!selectedReminderId) {
      void handleCreateInvitationReminder({
        projectId,
        title,
        description,
        delay,
        end,
        start,
        templateId,
        groupId: groupId === 0 ? undefined : groupId,
      });
    } else {
      void handleUpdateInvitationReminder({
        id,
        title,
        description,
        delay,
        end,
        start,
        templateId,
        groupId: groupId === 0 ? undefined : groupId,
      });
    }
  };

  useEffect(() => {
    if (!projectId) return;

    void handleGetPaginatedGroups({
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!selectedReminderId) return

    void handleGetInvitationReminder({
      id: selectedReminderId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReminderId]);

  const isLoading = isCreateInvitationReminderLoading || isUpdateInvitationReminderLoading || isDeleteInvitationReminderLoading || isGetInvitationReminderLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={closeSidebar}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            iconId: 'id_general_tab_icon',
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <InvitationRemindersGeneralTab
                initialReminder={currentReminder}
                isLoading={isLoading}
                onInputChange={handleInputChange}
              />
            ),
          },
          {
            iconId: 'id_units_tab_icon',
            id: 'id_condition_tab',
            label: 'Condition',
            view: () => (
              <InvitationRemindersConditionTab
                groupOptions={groupOptions}
                initialReminder={currentReminder}
                isLoading={isLoading}
                onInputChange={handleInputChange}
              />
            ),
          },
          {
            iconId: 'id_schedule_icon',
            id: 'id_schedule_tab',
            label: 'Schedule',
            view: () => (
              <InvitationRemindersScheduleTab
                initialReminder={currentReminder}
                isLoading={isLoading}
                onInputChange={handleInputChange}
              />
            ),
          },
          {
            iconId: 'id_notes_tab_icon',
            id: 'id_templates_tab',
            label: 'Templates',
            view: () => (
              <InvitationRemindersTemplatesTab
                initialReminder={currentReminder}
                isLoading={isLoading}
                projectId={String(projectId)}
                onInputChange={handleInputChange}
              />
            ),
          },
        ]}
        onDelete={selectedReminderId ? () => setIsDeleteDialogOpen(true): undefined}
        onSave={handleSubmit}
      />

      {selectedReminderId && currentReminder ? (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete reminder: ${currentReminder.title}?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => handleDeleteInvitationReminder({
            id: currentReminder.id
          })}
        />
      ) : <></>}
    </>
  );
};

export { InvitationRemindersSidebar };
