import React from 'react';

import { PageTemplate } from 'components/Shared';

import { InvitationRemindersTable } from './Tables';


const InvitationRemindersPage = () => {
  return (
    <>
      <PageTemplate
        activePage="scenarios"
        activeSubPage="invitations"
        description="Manage invitation reminders"
        pageTitle="Scheduler management"
      >
        <InvitationRemindersTable/>
      </PageTemplate>
    </>
  );
};

export { InvitationRemindersPage };
