import React, { useEffect, useState } from 'react';

import { Select, Skeleton, Spacing } from '@netfront/ui-library';
import { IDBInvitationReminder, IInvitationReminder } from 'interfaces';

import { invitationReminderConditionTabConstants } from './InvitationRemindersConditionTab.constants';
import { InvitationRemindersConditionTabProps } from './InvitationRemindersConditionTab.interfaces';

import { ConditionDelayInput } from '../../..';


const InvitationRemindersConditionTab = ({ onInputChange, initialReminder, isLoading = false, groupOptions = [] }: InvitationRemindersConditionTabProps) => {

  const { conditionDelayMinNumber } = invitationReminderConditionTabConstants;
  const [reminder, setReminder] = useState<IInvitationReminder>({} as IInvitationReminder);

  const { delay = 0, groupId} = reminder;


  const handleInputChange = ({name, value}: { name: string; value: number}) => {
    setReminder({
      ...reminder,
      [name]: value,
    });
    onInputChange({name: name as keyof IDBInvitationReminder, value})
  };

  useEffect(() => {
    setReminder(initialReminder ?? {} as IInvitationReminder);
  }, [initialReminder]);

  if (isLoading) return (
    <>
      <Skeleton additionalClassNames="c-label" width="10rem" />
      <Skeleton height="3rem" width="100%"/>
    </>
  );

  return (
    <>
      <Spacing size="large">
        <ConditionDelayInput
          id="step_started_type"
          initialValue={delay}
          labelText="Condition delay"
          minNumber={conditionDelayMinNumber}
          name="delay"
          tooltipText="The schedule will execute using the configuration below"
          onChange={handleInputChange}
        />
      </Spacing>

      <Spacing size="large">
        <Select
          id="id_group_select"
          labelText="Group"
          name="groupId"
          options={groupOptions}
          value={groupId}
          onChange={({ target: { name, value }}) => handleInputChange({name, value: Number(value)})}
        />
      </Spacing>
    </>
  );
};

export { InvitationRemindersConditionTab };
