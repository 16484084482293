import React, { useEffect, useState } from 'react';

import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { TableTemplate } from 'components/Shared/TableTemplate';
import { InvitationRemindersSidebar } from 'components/Sidebars';

import { INVITATION_SCENARIOS_TABLE_COLUMNS } from './InvitationRemindersTable.constants';
import { getInvitationRemindersTableData } from './InvitationRemindersTable.helpers';
import { IInvitationRemindersTableData } from './InvitationRemindersTable.interfaces';

import { useGetInvitationReminders, useToast } from '../../../../../../hooks';
import { IDBInvitationReminder } from '../../../../../../interfaces';
import { INVITATION_REMINDERS_PAGE_CONSTANTS } from '../../InvitationRemindersPage.constants';

const InvitationRemindersTable = () => {
  const { query } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { projectId } = query;
  const { defaultPageSize } = INVITATION_REMINDERS_PAGE_CONSTANTS;

  const [allReminders, setAllReminders] = useState<IDBInvitationReminder[]>();
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [remindersTableData, setRemindersTableData] = useState<IInvitationRemindersTableData[]>();
  const [selectedReminderId, setSelectedReminderId] = useState<number>();
  const [totalReminders, setTotalReminders] = useState<number>(0);

  const {
    handleFetchMorePaginatedInvitationReminders,
    handleGetPaginatedInvitationReminders,
    isLoading: isGetPaginatedRemindersLoading = false,
  } = useGetInvitationReminders({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ invitationRemindersConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const invitationReminder = edges.map(({ node }) => node);

      setAllReminders(invitationReminder);
      setIsLoadMoreDisabled(invitationReminder.length >= totalCount || totalCount <= pageSize);
      setTotalReminders(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewReminderClick = () => {
    setSelectedReminderId(undefined);
    setIsSideBarOpen(true);
  };

  const handleAddReminder = (invitationReminder: IDBInvitationReminder) => {
    setAllReminders((currentState): IDBInvitationReminder[] | undefined => {
      if (!currentState) {
        return;
      }

      return [...currentState, invitationReminder];
    });

    setTotalReminders(totalReminders + 1);
    handleSideBarClose();
    handleToastSuccess({
      message: 'Reminder created successfully',
    });
  };

  const handleDeletedReminder = (id: IDBInvitationReminder['id']) => {
    setAllReminders((currentState): IDBInvitationReminder[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.filter((invitationReminder) => {
        return invitationReminder.id !== id;
      });
    });

    setTotalReminders(totalReminders - 1);

    handleSideBarClose();
    handleToastSuccess({
      message: 'Reminder deleted successfully',
    });
  };

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedInvitationReminders({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedReminderId(undefined);
  };

  const handleUpdatedReminder = (invitationReminder: IDBInvitationReminder) => {
    setAllReminders((currentState): IDBInvitationReminder[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentReminder): IDBInvitationReminder => {
        return currentReminder.id === invitationReminder.id ? invitationReminder : currentReminder;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Reminder updated successfully',
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedInvitationReminders({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allReminders) {
      return;
    }

    setRemindersTableData(
      getInvitationRemindersTableData({
        invitationReminders: allReminders,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedReminderId(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReminders]);

  const isLoading = isGetPaginatedRemindersLoading;


  return (
    <>
      <TableTemplate<IInvitationRemindersTableData>

        columns={INVITATION_SCENARIOS_TABLE_COLUMNS}
        data={remindersTableData ?? []}
        handleAddNewClick={handleAddNewReminderClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedInvitationReminders({
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle={''}
        tableStats={
          {
            label: pluralize('invitation reminder', totalReminders),
            totalCount: totalReminders,
          }}
        tableType="invitation reminders"
        title="Invitation reminders"
        totalItems={totalReminders}
      />
      {
        projectId && (
          <InvitationRemindersSidebar
            handleAddReminder={handleAddReminder}
            handleDeletedReminder={handleDeletedReminder}
            handleSideBarClose={handleSideBarClose}
            handleUpdatedReminder={handleUpdatedReminder}
            isSideBarOpen={isSideBarOpen}
            projectId={String(projectId)}
            selectedReminderId={selectedReminderId}
          />
        )
      }

    </>
  );
};

export { InvitationRemindersTable };
