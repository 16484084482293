import React from 'react';

import { Input, Label } from '@netfront/ui-library';

import { ScheduleTabDailyProps } from './ScheduleTabDaily.interfaces';

import { scenarioScheduleTabConstants } from '../../ScenarioScheduleTab.constants';

const ScheduleTabDaily = ({ schedule, onUpdateScenarioInput }: ScheduleTabDailyProps) => {
  const { recurrenceMinNumber } = scenarioScheduleTabConstants;

  return (
    <div className="c-schedule-tab-daily">
      <Label forId="daily" labelText="Recurrence pattern" />
      <div className="c-scenario-sidebar-schedule-view__occurrence">
        {/* Update this with radio button after adding other option */}
        <span>Every</span>

        <div className="c-scenario-sidebar-schedule-view__occurrence-input-container">
          <Input
            additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
            id="occurEvery"
            labelText="occurEvery"
            minNumber={recurrenceMinNumber}
            name="occurEvery"
            type="number"
            value={schedule?.occurEvery}
            isLabelHidden
            isRequired
            onChange={onUpdateScenarioInput}
          />
          <span>Days</span>
        </div>
      </div>
    </div>
  );
};

export { ScheduleTabDaily };
