import { gql } from '@apollo/client';

const GET_CONDITIONS_QUERY = gql`
  query GetConditions($scenarioId: Int!) {
    scenarioQueries {
      getConditions(scenarioId: $scenarioId) {
        createdAt
        delay
        description
        id
        title
        updatedAt
        ... on ConditionDayGraphType {
          day
        }
        ... on ConditionHourGraphType {
          value
        }
        ... on ConditionSessionGraphType {
          comparator
        }
        ... on ConditionStepStartedGraphType {
          comparator
          stepId
        }
        ... on ConditionStepCompletedGraphType {
          comparator
          stepId
        }
        ... on ConditionUserActivatedGraphType {
          comparator
        }
        ... on ConditionUserLoginGraphType {
          comparator
        }
        ... on ConditionStepProgressionGraphType {
          comparator
          stepId
          percentage
          valueComparator
        }
      }
    }
  }
`;

export { GET_CONDITIONS_QUERY };
