/* eslint-disable import/order */
import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';

import {
  Input,
  Label,
  Spacing,
  Spinner,
  ToggleSwitch,
  Tooltip,
  DatePicker,
  TabSet,
  SidebarButtons,
} from '@netfront/ui-library';
import addYears from 'date-fns/addYears';

import { scenarioScheduleTabConstants } from './ScenarioScheduleTab.constants';
import { ScenarioScheduleTabProps } from './ScenarioScheduleTab.interfaces';
import { ScheduleTabDaily, ScheduleTabWeekly, ScheduleTabMonthly, ScheduleTabYearly } from './ScheduleTabs';

import { IDBScenario, IDBSchedule } from '../../../../interfaces';
import { useToast, useUpdateScheduleDetails, useUpdateSchedule, useDeleteSchedule } from '../../../../hooks';
import { DBConditionDayOfTheWeekType } from '../../../../types';

const ScenarioScheduleTab = ({ scenario, onClose, onUpdated, onUpdatedScenarioSchedule }: ScenarioScheduleTabProps) => {
  const { handleToastError, handleToastCustomError } = useToast();

  const { maxRecurrence: occurrence } = scenario;

  const {
    occurrenceMinNumber,
    inactiveScheduleTabDefaultNumberValue,
    maxDateAdditionalYear,
    recurrenceMinNumber,
    scheduleTabsetSelectionTypeMap,
    scheduleActiveTabScheduleGraphSelectionTypeMap,
  } = scenarioScheduleTabConstants;

  const maxDate = String(Number(new Date().getFullYear()) + maxDateAdditionalYear);



  const [activeTabId, setActiveTabId] = useState<string>('');
  const [isMultipleOccurrence, setIsMultipleOccurrence] = useState<boolean>(occurrence !== 1);
  const [updatedScenario, setUpdatedScenario] = useState<IDBScenario>(scenario);
  const [selectedWeeklyRecurrenceDays, setSelectedWeeklyRecurrenceDays] = useState<DBConditionDayOfTheWeekType[]>(
    scenario.schedule?.recurrenceDays ?? ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
  );

  const { endDate = undefined, maxRecurrence = 1, schedule, id: scenarioId, startDate = undefined } = updatedScenario;

  const [scheduleStartDate, setScheduleStartDate] = useState<Date | undefined>(startDate);
  const [scheduleEndDate, setScheduleEndDate] = useState<Date>(endDate ?? addYears(new Date(), 1));

  const handleSelectTab = (id: string) => {
    setActiveTabId(id);
  };

  const { handleUpdateScheduleDetails: executeUpdateScheduleDetails, isLoading: isUpdateScheduleDetailsLoading = false } =
    useUpdateScheduleDetails({
      onCompleted: (data) => {
        onUpdated(data as unknown as IDBScenario);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpdateSchedule: executeUpdateSchedule, isLoading: isUpdateScheduleLoading = false } = useUpdateSchedule({
    onCompleted: (data) => {
      onUpdatedScenarioSchedule(data, updatedScenario);

      setActiveTabId(String(scheduleTabsetSelectionTypeMap[data.__typename]));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });
  const { handleDeleteSchedule: executeDeleteSchedule, isLoading: isDeleteScheduleLoading = false } = useDeleteSchedule({
    onCompleted: () => {
      const removedSchedule = {
        ...scenario,
        schedule: {},
        maxRecurrence: recurrenceMinNumber,
      };

      setUpdatedScenario(removedSchedule as IDBScenario);
      onUpdated(removedSchedule as IDBScenario);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleToggleMultipleOccurrence = () => {
    setIsMultipleOccurrence(!isMultipleOccurrence);
    setActiveTabId('id_daily');

    if (!isMultipleOccurrence) {
      return;
    }

    executeDeleteSchedule({
      scenarioId,
    });
  };

  const handleUpdateScenarioInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setUpdatedScenario((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleUpdateScheduleInput = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    const updateScheduleObjectDefault = {
      ...schedule,
      [name]: Number(value),
      __typename: scheduleActiveTabScheduleGraphSelectionTypeMap[activeTabId],
    };

    const updateScheduleObject =
      activeTabId === 'id_weekly'
        ? {
          ...updateScheduleObjectDefault,
          recurrenceDays: selectedWeeklyRecurrenceDays,
        }
        : updateScheduleObjectDefault;

    setUpdatedScenario(
      (currentState) =>
        ({
          ...currentState,
          schedule: updateScheduleObject,
        } as IDBScenario),
    );
  };

  const handleChangeScheduleDayOptions = (values?: string[]) => {
    setSelectedWeeklyRecurrenceDays(values as DBConditionDayOfTheWeekType[]);

    setUpdatedScenario(
      (currentState) =>
        ({
          ...currentState,
          schedule: {
            ...currentState.schedule,
            recurrenceDays: values,
            __typename: 'ScheduleWeeklyGraphType',
          },
        } as IDBScenario),
    );
  };

  const handleUpdateStartDate = (date: Date | null) => {
    if (!date) return;

    setScheduleStartDate(date);

    setUpdatedScenario((currentState) => {
      return {
        ...currentState,
        startDate: date,
      };
    });
  };

  const handleUpdateEndDate = (date: Date | null) => {
    if (!date) return;

    setScheduleEndDate(date);

    setUpdatedScenario((currentState) => {
      return {
        ...currentState,
        endDate: date,
      };
    });
  };

  const handleUpdateScenarioSchedule = (scenarioSchedule: IDBSchedule) => {
    setUpdatedScenario((currentState) => {
      return {
        ...currentState,
        schedule: scenarioSchedule,
      };
    });
  };

  const handleUpdateSchedule = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (activeTabId && activeTabId === 'id_weekly' && selectedWeeklyRecurrenceDays.length === 0) {
      handleToastCustomError({ message: 'Please select at least 1 allowable day in the schedule tab to send reminder.' });
      return;
    }

    executeUpdateScheduleDetails({
      endDate,
      maxRecurrence,
      scenarioId,
      shouldIncludeConditions: false,
      startDate,
    });

    if (!activeTabId) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    executeUpdateSchedule({
      ...updateScheduleMutationVariablesMap[activeTabId],
    });
  };

  const updateScheduleMutationVariablesDefault = {
    scenarioId,
    shouldIncludeConditions: false,
  };

  const updateScheduleMutationVariablesMap = {
    'id_daily': {
      ...updateScheduleMutationVariablesDefault,
      daily: {
        occurEvery: Number(schedule?.occurEvery),
      },
    },
    'id_weekly': {
      ...updateScheduleMutationVariablesDefault,
      weekly: {
        occurEvery: schedule?.occurEvery,
        recurrenceDays: schedule?.recurrenceDays,
      },
    },
    'id_monthly': {
      ...updateScheduleMutationVariablesDefault,
      monthly: {
        occurEvery: Number(schedule?.occurEvery),
        day: Number(schedule?.day),
      },
    },
    'id_yearly': {
      ...updateScheduleMutationVariablesDefault,
      yearly: {
        occurEvery: Number(schedule?.occurEvery),
        day: Number(schedule?.day),
        month: Number(schedule?.month),
      },
    },
  };


  useEffect(() => {
    if (isMultipleOccurrence) {
      setUpdatedScenario(scenario);
      return;
    }

    setUpdatedScenario({
      ...updatedScenario,
      maxRecurrence: recurrenceMinNumber,
      delayBetweenNotification: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultipleOccurrence]);

  useEffect(() => {
    const initialActiveTab = scheduleTabsetSelectionTypeMap[scenario.schedule?.__typename ?? scheduleTabsetSelectionTypeMap.ScheduleDailyGraphType];

    if (initialActiveTab) {
      setActiveTabId(String(initialActiveTab));
    } else {
      setActiveTabId('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  useEffect(() => {
    if (scenario.schedule) {
      if (activeTabId === scheduleTabsetSelectionTypeMap[scenario.schedule.__typename]) {
        handleUpdateScenarioSchedule(scenario.schedule);
        return;
      }

      handleUpdateScenarioSchedule({
        ...scenario.schedule,
        occurEvery: inactiveScheduleTabDefaultNumberValue,
        day: inactiveScheduleTabDefaultNumberValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId]);

  const isLoading = isUpdateScheduleDetailsLoading || isDeleteScheduleLoading || isUpdateScheduleLoading;

  return (
    <form className="c-scenario-sidebar-schedule-view" onSubmit={handleUpdateSchedule}>
      <Spinner isLoading={isLoading} />

      <Spacing size="large">
        <Label forId="schedule-date-selection" labelText="Date" />

        <section className="c-scenario-sidebar-schedule-view__dates" id="schedule-date-selection">
          <DatePicker id="id_start_date_picker" labelText="Start" maxDate={maxDate ? new Date(maxDate): undefined} selectedDate={scheduleStartDate ? new Date(scheduleStartDate): undefined} onSingleDateChangeHandler={handleUpdateStartDate} />

          <DatePicker id="id_end_date_picker" labelText="End" maxDate={maxDate ? new Date(maxDate): undefined} selectedDate={new Date(scheduleEndDate)} isRequired onSingleDateChangeHandler={handleUpdateEndDate} />
        </section>
      </Spacing>

      <Spacing size="2x-large">
        <ToggleSwitch
          id="multiple-occurrence"
          isChecked={isMultipleOccurrence}
          labelText="Multiple occurrence"
          onChange={handleToggleMultipleOccurrence}
        />
      </Spacing>

      {isMultipleOccurrence && (
        <>
          <Spacing size="2x-large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <Label forId="occurrence" labelText="Occurrence" />

              <div className="c-scenario-sidebar-schedule-view__occurrence">
                <span>End after</span>

                <div className="c-scenario-sidebar-schedule-view__occurrence-input-container">
                  <Input
                    additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
                    id="maxRecurrence"
                    labelText="Occurrence"
                    minNumber={occurrenceMinNumber}
                    name="maxRecurrence"
                    type="number"
                    value={maxRecurrence}
                    isLabelHidden
                    onChange={handleUpdateScenarioInput}
                  />
                  <span>Occurrences</span>
                </div>
              </div>

              <Tooltip
                additionalClassNames="c-scenario-sidebar__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="How many times will this scenario run"
              />
            </section>
          </Spacing>

          <Spacing size="2x-large">
            <TabSet
              defaultActiveTabId={activeTabId}
              tabs={[
                {
                  id: 'id_daily',
                  label: 'Daily',
                  view: () => activeTabId === 'id_daily' ? <ScheduleTabDaily schedule={schedule} onUpdateScenarioInput={handleUpdateScheduleInput}/> : <></>,
                },
                {
                  id: 'id_weekly',
                  label: 'Weekly',
                  view: () =>  activeTabId === 'id_weekly' ? (
                    <ScheduleTabWeekly
                      schedule={schedule}
                      selectedWeeklyRecurrenceDays={selectedWeeklyRecurrenceDays}
                      onUpdateScenarioInput={handleUpdateScheduleInput}
                      onUpdateScheduleDayOptions={handleChangeScheduleDayOptions}
                    />
                  ): <></>,
                },
                {
                  id: 'id_monthly',
                  label: 'Monthly',
                  view: () => activeTabId === 'id_monthly' ?  <ScheduleTabMonthly schedule={schedule} onUpdateScenarioInput={handleUpdateScheduleInput}/>: <></>,
                },
                {
                  id: 'id_yearly',
                  label: 'Yearly',
                  view: () =>  activeTabId === 'id_yearly' ? <ScheduleTabYearly schedule={schedule} onUpdateScenarioInput={handleUpdateScheduleInput}/>: <></>,
                },
              ]}
              theme="tags"
              onTabSelect={handleSelectTab}
            />
          </Spacing>
        </>
      )}

      <SidebarButtons
        onCancel={onClose}
        onSaveButtonType="submit"
      />
    </form>
  );
};

export { ScenarioScheduleTab };
