import React, { useEffect, useState } from 'react';

import { IOption, Select, Spacing } from '@netfront/ui-library';
import { useGetPaginatedGroups, useToast } from 'hooks';

import { ConditionFormUserGroupTypeViewProps } from './ConditionFormUserGroupTypeView.interfaces';


const ConditionFormUserGroupTypeView = ({ currentCondition, projectId, onUpdateConditionUserGroupInput }: ConditionFormUserGroupTypeViewProps) => {
  const [groupOptions, setGroupOptions] = useState<IOption[]>([]);

  const { handleToastError } = useToast();
  const { handleGetPaginatedGroups } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges } }) => {

      const groups = edges.map(({ node }) => node);

      const groupsSelectOptions = groups.map(
        ({ id, name }): IOption => ({
          id,
          name,
          value: id,
        }),
      );

      setGroupOptions(groupsSelectOptions);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { groupId } = currentCondition ?? {};

  useEffect(() => {
    if (!projectId) return;

    void handleGetPaginatedGroups({
      projectId: String(projectId),
      status: ['ACTIVE']
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <div className="c-condition-form-user-login-type-view">
      <Spacing size="large">
        <Select
          id="id_group_select"
          labelText="Group"
          name="groupId"
          options={groupOptions}
          value={groupId}
          onChange={({ target: { name, value }}) => onUpdateConditionUserGroupInput({name, value: Number(value)})}
        />
      </Spacing>
    </div>
  );
};

export { ConditionFormUserGroupTypeView };
