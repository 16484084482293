import React, { ChangeEvent } from 'react';

import { InformationBox, Input, RadioButtonGroup, Select, Spacing, Tooltip } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';

import { ConditionFormStepProgressionTypeViewProps } from './ConditionStepProgressionTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';
import { createSyntheticEvent, getConditionStepSelectOptions } from '../../ConditionFormView.helpers';

const ConditionFormStepProgressionTypeView = ({
  conditionStepName,
  currentCondition,
  userFlowSteps,
  onUpdateConditionInput,
  onUpdateConditionStepSelect,
  onUpdateConditionDelayInput,
}: ConditionFormStepProgressionTypeViewProps) => {
  const { comparator, delay, percentage = 0, valueComparator } = currentCondition ?? {};

  const {
    conditionDelayMinNumber,
    conditionPercentageMaxNumber,
    conditionPercentageMinNumber,
    conditionMetOptions,
    conditionStepProgressionValueComparatorOptions,
  } = scenarioConditionTabConstants;

  const percentageValue = percentage > conditionPercentageMaxNumber ? conditionPercentageMaxNumber : percentage;



  const handleComparatorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    onUpdateConditionInput(event);
    if (value === 'NOT_EXISTS') {
      onUpdateConditionDelayInput({name: 'delay', value: 0});
      onUpdateConditionInput(createSyntheticEvent('valueComparator', ''));
      onUpdateConditionInput(createSyntheticEvent('percentage', '0'));
    };
  };

  return (
    <div className="c-condition-form-step-started-type-view">
      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Select
            id="condition-step"
            labelText="Time point"
            name="conditionStep"
            options={getConditionStepSelectOptions(userFlowSteps)}
            value={conditionStepName}
            isRequired
            onChange={onUpdateConditionStepSelect}
          />

          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Time point when the condition will be triggered"
          />
        </section>
      </Spacing>

      {userFlowSteps.length === 0 && (
        <Spacing size="x-large">
          <InformationBox iconId="id_warning_icon" message="Please create a userflow for the project" />
        </Spacing>
      )}



      <Spacing size="x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <RadioButtonGroup
            additionalClassNames="c-condition-form-view__radio-group"
            items={conditionMetOptions}
            name="comparator"
            selectedValue={comparator}
            title="Condition met*"
            onChange={handleComparatorChange}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Did the user trigger the condition during the specified delay?"
          />
        </section>
      </Spacing>

      {comparator && comparator === 'EXISTS' && (
        <>
          <Spacing size="large">
            <ConditionDelayInput
              id="step_progression_type"
              initialValue={delay ?? 0}
              labelText="Condition delay"
              minNumber={conditionDelayMinNumber}
              name="delay"
              tooltipText="The schedule will execute using the configuration below"
              onChange={onUpdateConditionDelayInput}
            />
          </Spacing>

          <Spacing size="x-large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <RadioButtonGroup
                additionalClassNames="c-condition-form-view__radio-group"
                items={conditionStepProgressionValueComparatorOptions}
                name="valueComparator"
                selectedValue={valueComparator}
                title="Value comparator"
                onChange={onUpdateConditionInput}
              />
              <Tooltip
                additionalClassNames="c-scenario-sidebar__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Choose the value comparator"
              />
            </section>
          </Spacing>

          <div className="c-condition-form-view__percentage">
            <Input
              additionalClassNames="c-condition-form-view__percentage-input"
              id="percentage"
              labelText="Condition percentage"
              maxNumber={conditionPercentageMaxNumber}
              minNumber={conditionPercentageMinNumber}
              name="percentage"
              type="number"
              value={percentageValue}
              isLabelHidden
              onChange={onUpdateConditionInput}
            />

            <span>Percent</span>
          </div>
        </>
      )}
    </div>
  );
};

export { ConditionFormStepProgressionTypeView };
