import React from 'react';

import { Input, Icon } from '@netfront/ui-library';
import cx from 'classnames';

import { InputCounterProps } from './InputCounter.interfaces';

const InputCounter = ({
  additionalClassNames,
  id,
  maxNumber,
  minNumber,
  value,
  name,
  onChange,
  onClickDecreaseCount,
  onClickIncreaseCount,
}: InputCounterProps) => {
  return (
    <div className={cx('c-input-counter', additionalClassNames)}>
      <div className="c-input-counter__counter">
        <button className="c-input-counter__button" title="minus" type="button" onClick={onClickDecreaseCount}>
          <Icon id="id_circle_minus_icon" />
        </button>
        <Input
          additionalClassNames="c-input-counter__input"
          id={String(id)}
          labelText=""
          maxNumber={maxNumber}
          minNumber={minNumber}
          name={name}
          type="number"
          value={value}
          onChange={onChange}
        />
        <button className="c-input-counter__button" title="plus" type="button" onClick={onClickIncreaseCount}>
          <Icon id="id_circle_plus_icon" />
        </button>
      </div>
    </div>
  );
};

export { InputCounter };
