import React from 'react';

import { Icon } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ISimulatorsTableData } from './SimulatorsTable.interfaces';

const MAX_DATE_ADDITIONAL_YEAR = 5;

const SIMULATORS_TABLE_COLUMNS: ReadonlyArray<Column<ISimulatorsTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Email</div>,
  },
  {
    accessor: 'medium',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Medium</div>,
  },
  {
    accessor: 'template',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Template</div>,
  },
  {
    accessor: 'previewButtonData',
    Cell: ({ value: { template, onClick } }) => (
      <button className="c-simulators-table__preview-button" title="Preview template" type="button" onClick={() => onClick(template)}>
        <Icon className="c-simulators-table__preview-button-icon" id="id_eye_show_icon" />
      </button>
    ),
  },
];

const simulatorsTableConstants = Object.freeze({
  maxDateAdditionalYear: MAX_DATE_ADDITIONAL_YEAR,
  SIMULATORS_TABLE_COLUMNS,
});

export { simulatorsTableConstants };
