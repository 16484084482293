// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { COPY_CONDITION_MUTATION } from './useCopyCondition.graphql';
import {
  ICopyConditionMutationGraphQLResponse,
  ICopyConditionMutationVariables,
  IHandleCopyConditionParams,
  IUseCopyConditionOptions,
  IUseCopyCondition,
} from './useCopyCondition.interfaces';

const useCopyCondition = (options?: IUseCopyConditionOptions): IUseCopyCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCopyConditionOptions);

  const [executeCopyCondition, { loading: isLoading }] = useCapuchinMutation<
    ICopyConditionMutationGraphQLResponse,
    ICopyConditionMutationVariables
  >({
    mutation: mutation ?? COPY_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { copy: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleCopyCondition = ({ conditionGroupId, conditionId }: IHandleCopyConditionParams) => {
    void executeCopyCondition({
      variables: {
        conditionGroupId,
        conditionId,
      },
    });
  };

  return {
    handleCopyCondition,
    isLoading,
  };
};

export { useCopyCondition };
