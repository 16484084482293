import { gql } from '@apollo/client';

const SCENARIO_FRAGMENT = gql`
  fragment scenarioFragment on ScenarioGraphType {
    carbonCopies
    conditions @include(if: $shouldIncludeConditions) {
      __typename
    }
    createdDate
    delayBetweenNotification
    delayBetweenNotificationInDay
    delayBetweenNotificationInMonth
    delayBetweenNotificationInYear
    delayBetweenNotificationInWeek
    description
    endDate
    id
    isTestPhase
    maxRecurrence
    schedule {
      ... on ScheduleDailyGraphType {
        occurEvery
      }
      ... on ScheduleWeeklyGraphType {
        occurEvery
        recurrenceDays
      }
      ... on ScheduleMonthlyGraphType {
        day
        occurEvery
      }
      ... on ScheduleYearlyGraphType {
        day
        month
        occurEvery
      }
    }
    startDate
    status
    subject
    title
    updatedDate
    overrideReceiver
  }
`;

export { SCENARIO_FRAGMENT };
