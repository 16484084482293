import { useHowlerLazyQuery } from '../howler/useHowlerLazyQuery';

import { GET_PAGINATED_TEMPLATES_QUERY } from './useGetTemplates.graphql';
import {
  IGetPaginatedTemplatesQueryGraphQLResponse,
  IGetPaginatedTemplatesQueryVariables,
  IHandleGetPaginatedTemplatesParams,
  IUseGetPaginatedTemplates,
  IUseGetPaginatedTemplatesOptions,
} from './useGetTemplates.interfaces';

const useGetPaginatedTemplates = (options?: IUseGetPaginatedTemplatesOptions): IUseGetPaginatedTemplates => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedTemplatesOptions);

  const [executeGetPaginatedTemplates, { loading: isLoading }] = useHowlerLazyQuery<
    IGetPaginatedTemplatesQueryGraphQLResponse,
    IGetPaginatedTemplatesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { search: templatesConnection },
        } = data;

        onCompleted({
          templatesConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_TEMPLATES_QUERY,
    token,
  });

  const handleGetPaginatedTemplates = ({ after, filter, first, projectId }: IHandleGetPaginatedTemplatesParams) => {
    void executeGetPaginatedTemplates({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleGetPaginatedTemplates,
    isLoading,
  };
};

export { useGetPaginatedTemplates };
