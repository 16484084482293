import { IGetSimulatorsTableDataParams, ISimulatorsTableData } from './SimulatorsTable.interfaces';

const getSimulatorsTableData = ({ simulators, onPreviewButtonClick }: IGetSimulatorsTableDataParams): ISimulatorsTableData[] => {
  return simulators.map(({ template, user }): ISimulatorsTableData => {
    return {
      id: user.id,
      email: user.email,
      firstName: user.firstname,
      lastName: user.lastname,
      medium: template.type,
      previewButtonData: {
        template,
        onClick: onPreviewButtonClick,
      },
      template: template.title,
    };
  });
};

export { getSimulatorsTableData };
