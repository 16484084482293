import React from 'react';

import { CheckboxGroup, Input, Spacing } from '@netfront/ui-library';

import { ScheduleTabWeeklyProps } from './ScheduleTabWeekly.interfaces';

import { scenarioScheduleTabConstants } from '../../ScenarioScheduleTab.constants';

const ScheduleTabWeekly = ({
  schedule,
  selectedWeeklyRecurrenceDays = [],
  onUpdateScheduleDayOptions,
  onUpdateScenarioInput,
}: ScheduleTabWeeklyProps) => {
  const { recurrenceMinNumber, scheduleDayOptions } = scenarioScheduleTabConstants;

  return (
    <div className="c-schedule-tab-weekly">
      <Spacing size="x-large">
        <CheckboxGroup
          items={scheduleDayOptions}
          legendText="Allowable sending days"
          name="recurrenceDays"
          values={selectedWeeklyRecurrenceDays.length > 0 ? selectedWeeklyRecurrenceDays : undefined}
          isRequired
          onChange={onUpdateScheduleDayOptions}
        />
      </Spacing>

      <Spacing>
        <div className="c-scenario-sidebar-schedule-view__occurrence">
          <span>Every</span>

          <div className="c-scenario-sidebar-schedule-view__occurrence-input-container">
            <Input
              additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
              id="occurEvery"
              labelText="occurEvery"
              minNumber={recurrenceMinNumber}
              name="occurEvery"
              type="number"
              value={schedule?.occurEvery}
              isLabelHidden
              isRequired
              onChange={onUpdateScenarioInput}
            />
            <span>Weeks</span>
          </div>
        </div>
      </Spacing>
    </div>
  );
};

export { ScheduleTabWeekly };
