import { ITableStats } from '../interfaces';

const getInformationBoxMessageTableStats = ({
  customMessage  = '',
  pageName,
  projectName,
  tableStats,
}: {
  customMessage?: string
  pageName?: string,
  projectName: string,
  tableStats?: ITableStats,
}) => {
  if (customMessage) return customMessage;
  const message = pageName ? `Manage ${projectName} ${pageName} information` : `Manage ${projectName} information`;

  return tableStats ? `${message}: ${tableStats.totalCount} ${tableStats.label}` : message;
};

export { getInformationBoxMessageTableStats };
