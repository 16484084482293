import { gql } from '@apollo/client';

const CREATE_INVITATION_REMINDER = gql`
  mutation createInvitationReminder(
    $request: InvitationReminderInputGraphType!
    $projectId: String!
  ) {
    invitationReminders {
      add(projectId: $projectId, request: $request) {
        id
        delay
        description
        end
        start
        templateId
        title
        groupId
      }
    }
  }
`;

export { CREATE_INVITATION_REMINDER };
