import { gql } from '@apollo/client';


const GET_INVITATION_REMINDERS = gql`
  query GetInvitationReminders(
    $after: String = null
    $filter: String = null
    $first: Int = null
    $projectId: String!
  ) {
    invitationReminders {
      list(after: $after, first: $first, filter: $filter, projectId: $projectId) {
        edges {
          cursor
          node {
            delay
            id
            start
            end
            description
            templateId
            template {
              id
              title
              type
            }
            title
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_INVITATION_REMINDERS };
