import { gql } from '@apollo/client';

const GET_TEST_USERS_QUERY = gql`
  query GetTestUsers($after: String = null, $filter: String = null, $first: Int = null, $projectId: String!) {
    projectTestUserQueries {
      get(after: $after, filter: $filter, first: $first, projectId: $projectId) {
        edges {
          cursor
          node {
            email
            firstname
            id
            lastname
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { GET_TEST_USERS_QUERY };
