import React from 'react';

import { PageTemplate } from 'components/Shared';

import { TestUsersTable } from './Tables';


const TestModePage = () => {
  return (
    <>
      <PageTemplate
        activePage="test-mode"
        description="Manage schedules"
        pageTitle="Scheduler management"
      >
        <TestUsersTable/>
      </PageTemplate>
    </>
  );
};

export { TestModePage };
