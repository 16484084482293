import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  Dialog,
  Icon,
  InformationBox,
  Label,
  Preloader,
  SearchWithButtonList,
  Skeleton,
  Spacing,
  Spinner,
  Tooltip,
} from '@netfront/ui-library';
import capitalize from 'lodash/capitalize';
import { useDebouncedCallback } from 'use-debounce';

import { invitationReminderTemplatesTabConstants } from './InvitationRemindersTemplatesTab.constants';
import {
  IInvitationReminderSidebarSearchTemplates,
  IInvitationReminderSidebarCurrentTemplates,
  InvitationReminderTemplatesTabProps,
} from './InvitationRemindersTemplatesTab.interfaces';

import { useGetPaginatedTemplates, useGetTemplate, useToast } from '../../../../hooks';
import { IDBTemplate } from '../../../../interfaces';

const InvitationRemindersTemplatesTab = ({ projectId, initialReminder, onInputChange, isLoading = false }: InvitationReminderTemplatesTabProps) => {
  const { handleToastError } = useToast();

  const {
    debounceQueryWaitTimeInMilliseconds,
  } = invitationReminderTemplatesTabConstants;

  const [isTemplatePreviewDialogOpen, setIsTemplatePreviewDialogOpen] = useState<boolean>(false);
  const [scenarioTemplateContent, setScenarioTemplateContent] = useState<IDBTemplate['content']>();
  const [templates, setTemplates] = useState<IInvitationReminderSidebarSearchTemplates[]>([]);

  const [currentTemplate, setCurrentTemplate] = useState<IInvitationReminderSidebarCurrentTemplates | undefined>();

  const { handleGetPaginatedTemplates: executeGetPaginatedTemplates, isLoading: isGetPaginatedTemplatesLoading } = useGetPaginatedTemplates(
    {
      onCompleted: ({ templatesConnection: { edges } }) => {
        const allTemplates = edges.map(({ node }) => node);

        const searchTemplates = allTemplates.map((template) => {
          const { id, name } = template;

          return {
            id,
            title: name,
          };
        });

        setTemplates(searchTemplates);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );


  const { handleGetTemplate: executeGetTemplate, isLoading: isGetTemplateLoading } = useGetTemplate({
    onCompleted: ({ templateConnection: template }) => {
      const { content } = template;

      setIsTemplatePreviewDialogOpen(true);
      setScenarioTemplateContent(content);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleDebounceGetTemplates = useDebouncedCallback((inputText) => {
    executeGetPaginatedTemplates({
      projectId,
      first: 10,
      filter: inputText,
    });
  }, Number(debounceQueryWaitTimeInMilliseconds));


  const handleGetTemplate = (template: IInvitationReminderSidebarCurrentTemplates) => {
    executeGetTemplate({
      templateId: template.id,
    });
  };

  const handleRecommendedSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value.length <= 2) {
      return;
    }

    handleDebounceGetTemplates(value);
  };

  const handleSetTemplate = (selectedScenarioTemplate: IInvitationReminderSidebarSearchTemplates | null) => {
    if (!selectedScenarioTemplate) {
      return;
    }

    setCurrentTemplate(selectedScenarioTemplate);

    onInputChange({
      name: 'templateId',
      value: selectedScenarioTemplate.id
    });
  };

  useEffect(() => {
    if (!initialReminder) {
      return;
    }

    const { template } = initialReminder;

    const { id, title, type } = template;

    const templateType = String(type).split('_').length > 1 ? capitalize(String(type).split('_').join(' ').toLocaleLowerCase()) : type;

    setCurrentTemplate({
      id,
      title: `${String(title)} ${String(templateType)}`
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReminder]);


  const isApiLoading = isGetPaginatedTemplatesLoading;

  if (isLoading) return <Skeleton height="10rem" />

  return (
    <div className="c-scenario-sidebar-templates-view">
      <Spinner isLoading={isGetTemplateLoading ?? false} />


      <Spacing size="2x-large">
        <section className="c-scenario-sidebar-templates-view__search-section">
          <Tooltip
            additionalClassNames="c-scenario-sidebar-templates-view__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Search for existing templates within the project templates library"
          />

          <SearchWithButtonList
            labelText="Copy existing template"
            listItems={templates}
            onChange={handleRecommendedSearchInput}
            onClick={handleSetTemplate}
          />
        </section>
      </Spacing>

      <Spacing size="2x-large">
        {isApiLoading ? (
          <Preloader isLoading={isApiLoading} />
        ) : (
          <section className="c-scenario-sidebar-templates-view__list-section">
            <Label forId="templates-list" labelText="Current templates" />

            {currentTemplate ? (
              <div>
                <ul className="c-scenario-sidebar-templates-view__templates-list" id="templates-list">
                  <li
                    className="c-scenario-sidebar-templates-view__templates-item c-scenario-sidebar-templates-view__templates-item--one"
                  >
                    <div>
                      <button
                        className="c-scenario-sidebar-templates-view__preview-button"
                        title="Preview template"
                        type="button"
                        onClick={() => handleGetTemplate(currentTemplate)}
                      >
                        <Icon id="id_eye_show_icon" />
                      </button>
                      <span className="c-scenario-sidebar-templates-view__templates__name">{currentTemplate.title}</span>

                      <Dialog
                        isOpen={isTemplatePreviewDialogOpen}
                        title={`Preview template: ${String(currentTemplate.title)}`}
                        onCancel={() => setIsTemplatePreviewDialogOpen(false)}
                        onClose={() => setIsTemplatePreviewDialogOpen(false)}
                      >
                        {scenarioTemplateContent ? (
                          <div dangerouslySetInnerHTML={{ __html: scenarioTemplateContent }} />
                        ) : (
                          'No content available for the template'
                        )}
                      </Dialog>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <InformationBox message="No templates attached for the scenario" />
            )}
          </section>
        )}
      </Spacing>
    </div>
  );
};

export { InvitationRemindersTemplatesTab };
