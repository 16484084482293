import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_GROUPS_MUTATION } from './useGetPaginatedGroups.graphql';
import {
  IGetPaginatedGroupsQueryGraphQLResponse,
  IGetPaginatedGroupsQueryVariables,
  IHandleFetchMorePaginatedGroupsParams,
  IHandleGetPaginatedGroupsParams,
  IUseGetPaginatedGroups,
  IUseGetPaginatedGroupsOptions,
} from './useGetPaginatedGroups.interfaces';

const useGetPaginatedGroups = (options?: IUseGetPaginatedGroupsOptions): IUseGetPaginatedGroups => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedGroupsOptions);

  const [executeGetPaginatedGroups, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetPaginatedGroupsQueryGraphQLResponse,
  IGetPaginatedGroupsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ group: { getPaginatedGroups: groupConnection } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          groupConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_GROUPS_MUTATION,
    token,
  });

  const handleFetchMorePaginatedGroups = ({
    after,
    createdFrom,
    createdTo,
    filter,
    first,
    maxUsers,
    minUsers,
    projectId,
    shouldIncludeUserType = false,
    shouldIncludeAddress = false,
    shouldIncludeSmartCodes = false,
    shouldIncludeUnit = false,
    status,
  }: IHandleFetchMorePaginatedGroupsParams): Promise<ApolloQueryResult<IGetPaginatedGroupsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            group: { getPaginatedGroups: newlyFetchedGroupConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedGroupConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          group: { getPaginatedGroups: previouslyFetchedMembershipConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedMembershipConnection;

        return {
          ...previousQueryResult,
          group: {
            ...previouslyFetchedMembershipConnection,
            getPaginatedGroups: {
              ...newlyFetchedGroupConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        createdFrom,
        createdTo,
        filter,
        first,
        maxUsers,
        minUsers,
        projectId,
        shouldIncludeUserType,
        shouldIncludeAddress,
        shouldIncludeSmartCodes,
        shouldIncludeUnit,
        status,
      },
    });
  };

  const handleGetPaginatedGroups = async ({
    after,
    createdFrom,
    createdTo,
    filter,
    first,
    maxUsers,
    minUsers,
    projectId,
    shouldIncludeUserType = false,
    shouldIncludeAddress = false,
    shouldIncludeSmartCodes = false,
    shouldIncludeUnit = false,
    status,
  }: IHandleGetPaginatedGroupsParams) => {
    await executeGetPaginatedGroups({
      variables: {
        after,
        createdFrom,
        createdTo,
        filter,
        first,
        maxUsers,
        minUsers,
        projectId,
        shouldIncludeUserType,
        shouldIncludeAddress,
        shouldIncludeSmartCodes,
        shouldIncludeUnit,
        status,
      },
    });
  };

  return {
    handleFetchMorePaginatedGroups,
    handleGetPaginatedGroups,
    isLoading,
  };
};

export { useGetPaginatedGroups };
