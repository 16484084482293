// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { ADD_CONDITION_MUTATION } from './useAddCondition.graphql';
import {
  IAddConditionMutationGraphQLResponse,
  IAddConditionMutationVariables,
  IHandleAddConditionParams,
  IUseAddConditionOptions,
  IUseAddCondition,
} from './useAddCondition.interfaces';

const useAddCondition = (options?: IUseAddConditionOptions): IUseAddCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddConditionOptions);

  const [executeAddCondition, { loading: isLoading }] = useCapuchinMutation<
  IAddConditionMutationGraphQLResponse,
  IAddConditionMutationVariables
  >({
    mutation: mutation ?? ADD_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { add: conditionConnection },
        } = data;

        const { condition } = conditionConnection;

        onCompleted(condition);
      },
      onError,
    },
    token,
  });

  const handleAddCondition = ({
    day,
    hour,
    stepStarted,
    stepCompleted,
    stepProgression,
    userActivated,
    userLogin,
    userRegistered,
    userLastLogin,
    userSession,
    userGroup,
  }: IHandleAddConditionParams) => {
    void executeAddCondition({
      variables: {
        day,
        hour,
        stepStarted,
        stepCompleted,
        stepProgression,
        userActivated,
        userLogin,
        userRegistered,
        userLastLogin,
        userSession,
        userGroup,
      },
    });
  };

  return {
    handleAddCondition,
    isLoading,
  };
};

export { useAddCondition };
