// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { ADD_SCENARIO_MUTATION } from './useAddScenario.graphql';
import {
  IAddScenarioMutationGraphQLResponse,
  IAddScenarioMutationVariables,
  IHandleAddScenarioParams,
  IUseAddScenarioOptions,
  IUseAddScenario,
} from './useAddScenario.interfaces';

const useAddScenario = (options?: IUseAddScenarioOptions): IUseAddScenario => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddScenarioOptions);

  const [executeAddScenario, { loading: isLoading }] = useCapuchinMutation<
  IAddScenarioMutationGraphQLResponse,
  IAddScenarioMutationVariables
  >({
    mutation: mutation ?? ADD_SCENARIO_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioMutations: { addScenario: scenario },
        } = data;

        onCompleted({
          scenario,
        });
      },
      onError,
    },
    token,
  });

  const handleAddScenario = ({ projectId, description, isTestPhase, shouldIncludeConditions, title, overrideReceiver }: IHandleAddScenarioParams) => {
    void executeAddScenario({
      variables: {
        projectId,
        description,
        isTestPhase,
        shouldIncludeConditions,
        title,
        overrideReceiver,
      },
    });
  };

  return {
    handleAddScenario,
    isLoading,
  };
};

export { useAddScenario };
