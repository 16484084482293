// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { DELETE_CONDITION_MUTATION } from './useDeleteCondition.graphql';
import {
  IDeleteConditionMutationGraphQLResponse,
  IDeleteConditionMutationVariables,
  IHandleDeleteConditionParams,
  IUseDeleteConditionOptions,
  IUseDeleteCondition,
} from './useDeleteCondition.interfaces';

const useDeleteCondition = (options?: IUseDeleteConditionOptions): IUseDeleteCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteConditionOptions);

  const [executeDeleteCondition, { loading: isLoading }] = useCapuchinMutation<
    IDeleteConditionMutationGraphQLResponse,
    IDeleteConditionMutationVariables
  >({
    mutation: mutation ?? DELETE_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCondition = ({ conditionId }: IHandleDeleteConditionParams) => {
    void executeDeleteCondition({
      variables: {
        conditionId,
      },
    });
  };

  return {
    handleDeleteCondition,
    isLoading,
  };
};

export { useDeleteCondition };
