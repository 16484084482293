import { gql } from '@apollo/client';

import { SCENARIO_FRAGMENT } from '../../../fragments';

const UPDATE_SCENARIO_MUTATION = gql`
  mutation UpdateScenario(
    $description: String
    $id: Int!
    $isTestPhase: Boolean!
    $shouldIncludeConditions: Boolean!
    $status: EScenarioStatus!
    $title: String!
    $overrideReceiver: String
  ) {
    scenarioMutations {
      updateScenario(description: $description, id: $id, isTestPhase: $isTestPhase, status: $status, title: $title, overrideReceiver: $overrideReceiver) {
        ...scenarioFragment
      }
    }
  }

  ${SCENARIO_FRAGMENT}
`;

export { UPDATE_SCENARIO_MUTATION };
