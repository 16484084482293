import { gql } from '@apollo/client';

const DELETE_SCHEDULE_MUTATION = gql`
  mutation DeleteSchedule($scenarioId: Int!) {
    schedule {
      removeSchedule(id: $scenarioId)
    }
  }
`;

export { DELETE_SCHEDULE_MUTATION };
