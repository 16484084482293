import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  Icon,
  InformationBox,
  Label,
  NavigationButton,
  Preloader,
  SettingsButton,
  SidebarButtons,
  Spacing,
  Tooltip,
} from '@netfront/ui-library';

import { ConditionFormView, ISelectedCondition } from './ConditionFormView';
import { scenarioConditionTabConstants } from './ScenarioConditionTab.constants';
import { ScenarioConditionTabProps } from './ScenarioConditionTab.interfaces';

import { ToggleButtons } from '../../..';
import {
  useAddConditionGroup,
  useDeleteConditionGroup,
  useGetConditionGroups,
  useSortConditionGroup,
  useToast,
  useUpdateConditionGroup,
} from '../../../../hooks';
import { IDBCondition, IDBConditionGroup } from '../../../../interfaces';
import { DBLogicOperatorType } from '../../../../types';

const ScenarioConditionTab = ({ projectId, scenarioId, onClose }: ScenarioConditionTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const { conditionGroupOperatorTypesOptions } = scenarioConditionTabConstants;

  const [currentConditionGroups, setCurrentConditionGroups] = useState<IDBConditionGroup[]>();
  const [isConditionFormVisible, setIsConditionFormVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedCondition, setSelectedCondition] = useState<ISelectedCondition>();
  const [selectedConditionGroupId, setSelectedConditionGroupId] = useState<IDBConditionGroup['id']>();

  const { handleAddConditionGroup, isLoading: isAddConditionGroupLoading = false } = useAddConditionGroup({
    onCompleted: (data) => {
      if (!currentConditionGroups) {
        return;
      }

      setCurrentConditionGroups([...currentConditionGroups, data]);

      handleToastSuccess({
        message: 'Condition group created successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSortConditionGroup } = useSortConditionGroup({
    onCompleted: (data) => {
      handleSortConditionGroupCompleted(data);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteConditionGroup, isLoading: isDeleteConditionGroupLoading = false } = useDeleteConditionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Condition group deleted successfully',
      });

      setIsDeleteDialogOpen(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetConditionGroups, isLoading: isGetConditionGroupsLoading = false } = useGetConditionGroups({
    onCompleted: ({ conditionGroupConnection: conditionGroups }) => {
      setCurrentConditionGroups(conditionGroups);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateConditionGroup } = useUpdateConditionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Condition group updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleClickAddButton = (conditionGroupId: number) => {
    setSelectedConditionGroupId(conditionGroupId);
    setSelectedCondition(undefined);
    handleOpenConditionForm();
  };

  const handleClickDeleteConditionGroup = (conditionGroupId: IDBConditionGroup['id']) => {
    handleDeleteConditionGroup({
      conditionGroupId,
    });

    setCurrentConditionGroups(currentConditionGroups?.filter((conditionGroup) => conditionGroup.id !== conditionGroupId));
  };

  const handleClickSettingsButton = (condition: IDBCondition, conditionGroupId: number) => {
    setSelectedConditionGroupId(conditionGroupId);
    setSelectedCondition(condition as ISelectedCondition);
    handleOpenConditionForm();
  };

  const handleSortConditionGroupCompleted = (data: IDBConditionGroup) => {
    const { sort: sortedConditionGroupIndex, id: sortedConditionGroupId } = data;

    if (!currentConditionGroups) {
      return;
    }

    const filteredConditionGroups = currentConditionGroups.filter((conditionGroup) => conditionGroup.id !== sortedConditionGroupId);

    const splicedConditionGroups = [...filteredConditionGroups];
    splicedConditionGroups.splice(sortedConditionGroupIndex, 0, data);

    setCurrentConditionGroups(splicedConditionGroups);

    handleToastSuccess({
      message: 'Condition moved successfully',
    });
  };

  const handleClickToggleButton = (event: ChangeEvent<HTMLInputElement>, currentConditionGroup: IDBConditionGroup) => {
    event.preventDefault();

    const {
      target: { value },
    } = event;

    handleUpdateConditionGroup({
      conditionGroupId: currentConditionGroup.id,
      operator: value as DBLogicOperatorType,
    });

    const newGroup = [...(currentConditionGroups as IDBConditionGroup[])];

    const newData = newGroup.map((item) => {
      if (item.id !== currentConditionGroup.id) {
        return item;
      }

      return {
        ...item,
        operator: currentConditionGroup.operator === 'AND' ? 'OR' : 'AND',
      };
    });

    setCurrentConditionGroups(newData as IDBConditionGroup[]);
  };

  const handleOpenConditionForm = () => {
    setIsConditionFormVisible(!isConditionFormVisible);
  };

  useEffect(() => {
    handleGetConditionGroups({
      scenarioId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    isGetConditionGroupsLoading || isAddConditionGroupLoading || isDeleteConditionGroupLoading; /*|| isCopyConditionLoading*/

  return (
    <div className="c-scenario-sidebar-condition-view">

      {!isConditionFormVisible ? (
        <>
          {isLoading ? (
            <Preloader isLoading={isLoading} />
          ) : (
            currentConditionGroups &&
            Boolean(currentConditionGroups.length) &&
            currentConditionGroups.map((currentConditionGroup, index) => {
              const { id: conditionGroupId, conditions, operator, sort } = currentConditionGroup;

              const conditionsList = conditions.map((currentCondition) => {
                const { id, title } = currentCondition;

                return (
                  <li key={`${id}-${title}`} className="c-scenario-sidebar-condition-view__condition-item">
                    <span className="c-scenario-sidebar-condition-view__condition__name">{title}</span>

                    <SettingsButton
                      supportiveText="Open condition"
                      onClick={() => handleClickSettingsButton(currentCondition, conditionGroupId)}
                    />
                  </li>
                );
              });

              return (
                <div key={`${conditionGroupId}-${operator}`} id={`${conditionGroupId}-${operator}`}>
                  <Spacing size="2x-large">
                    <section className="c-sidebar-section c-sidebar-section--aligned">
                      <Spacing size="small">
                        <div className="c-sidebar-section__toggle-buttons">
                          {index !== 0 ? (
                            <ToggleButtons
                              key={`${conditionGroupId}-${operator}`}
                              items={conditionGroupOperatorTypesOptions}
                              name={`condition-group-operator-${conditionGroupId}`}
                              selectedValue={operator}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                handleClickToggleButton(event, currentConditionGroup);
                              }}
                            />
                          ) : (
                            <Label forId={`condition-groups-list`} labelText="Condition groups" />
                          )}

                          {currentConditionGroups.length > 1 && (
                            <div className="c-scenario-sidebar-condition-view__condition-item-sort">
                              {index !== 0 && index !== currentConditionGroups.length - 1 && (
                                <>
                                  <NavigationButton
                                    direction="previous"
                                    iconId="id_sort_up_half_icon"
                                    onClick={() =>
                                      handleSortConditionGroup({
                                        conditionGroupId,
                                        sort: sort - 1,
                                      })
                                    }
                                  />
                                  <NavigationButton
                                    direction="next"
                                    iconId="id_sort_down_half_icon"
                                    onClick={() =>
                                      handleSortConditionGroup({
                                        conditionGroupId,
                                        sort: sort + 1,
                                      })
                                    }
                                  />
                                </>
                              )}

                              {index === 0 ? (
                                <NavigationButton
                                  direction="previous"
                                  iconId="id_sort_down_full_icon"
                                  onClick={() =>
                                    handleSortConditionGroup({
                                      conditionGroupId,
                                      sort: sort + 1,
                                    })
                                  }
                                />
                              ) : null}

                              {index === currentConditionGroups.length - 1 ? (
                                <NavigationButton
                                  direction="next"
                                  iconId="id_sort_up_full_icon"
                                  onClick={() =>
                                    handleSortConditionGroup({
                                      conditionGroupId,
                                      sort: sort - 1,
                                    })
                                  }
                                />
                              ) : null}
                            </div>
                          )}

                          <Dialog
                            isOpen={isDeleteDialogOpen}
                            title={`Delete condition group with all the conditions?`}
                            isNarrow
                            onCancel={() => setIsDeleteDialogOpen(false)}
                            onClose={() => setIsDeleteDialogOpen(false)}
                            onConfirm={() => handleClickDeleteConditionGroup(conditionGroupId)}
                          />
                        </div>
                      </Spacing>

                      <Spacing>
                        {conditionsList.length ? (
                          conditionsList
                        ) : (
                          <InformationBox message="No conditions created for the condition group" />
                        )}
                      </Spacing>

                      <section className="c-scenario-sidebar-condition-view__add-section">
                        <Button text="Delete" type="button" variant="danger--tertiary" onClick={() => setIsDeleteDialogOpen(true)} />

                        <button
                          className="c-scenario-sidebar-condition-view__add"
                          title="Add condition"
                          type="button"
                          onClick={() => handleClickAddButton(conditionGroupId)}
                        >
                          <Icon className="c-scenario-sidebar-condition-view__add-icon" id="id_add_rectangle_icon" />
                        </button>
                      </section>
                    </section>
                  </Spacing>
                </div>
              );
            })
          )}

          {isLoading ? (
            <Preloader isLoading={isLoading} />
          ) : (
            <section className="c-scenario-sidebar-condition-view__add-group-section">
              <Tooltip
                additionalClassNames="c-scenario-sidebar-condition-view__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Add new condition group"
              />

              <Button
                size="xs"
                text="Add new group"
                type="button"
                variant="primary-inverse"
                onClick={() => {
                  handleAddConditionGroup({
                    scenarioId,
                    operator: 'AND',
                  });
                }}
              />
            </section>
          )}
          <SidebarButtons
            onCancel={onClose}
          />
        </>
      ) : (
        <ConditionFormView
          projectId={projectId}
          scenarioId={scenarioId}
          selectedCondition={selectedCondition}
          selectedConditionGroupId={selectedConditionGroupId}
          onGetConditionGroups={handleGetConditionGroups}
          onOpenConditionForm={handleOpenConditionForm}
        />
      )}
    </div>
  );
};

export { ScenarioConditionTab };
