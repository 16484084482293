// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { DELETE_SCENARIO_MUTATION } from './useDeleteScenario.graphql';
import {
  IDeleteScenarioMutationGraphQLResponse,
  IDeleteScenarioMutationVariables,
  IHandleDeleteScenarioParams,
  IUseDeleteScenarioOptions,
  IUseDeleteScenario,
} from './useDeleteScenario.interfaces';

const useDeleteScenario = (options?: IUseDeleteScenarioOptions): IUseDeleteScenario => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteScenarioOptions);

  const [executeDeleteScenario, { loading: isLoading }] = useCapuchinMutation<
    IDeleteScenarioMutationGraphQLResponse,
    IDeleteScenarioMutationVariables
  >({
    mutation: mutation ?? DELETE_SCENARIO_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioMutations: { deleteScenario: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteScenario = ({ scenarioId }: IHandleDeleteScenarioParams) => {
    void executeDeleteScenario({
      variables: {
        scenarioId,
      },
    });
  };

  return {
    handleDeleteScenario,
    isLoading,
  };
};

export { useDeleteScenario };
