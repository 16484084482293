import { useEffect, useState } from 'react';

import { IUser, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, IDropdownNavigationItem, LogoAvatar, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';

const UserAccountMenu = () => {
  const { getUser } = useUser();

  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [loggedUser, setLoggedUser] = useState<IUser>()
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLoggedUser(getUser());
    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!loggedUser) {
      return;
    }

    const {firstName = '', lastName = '', email, pictures } = loggedUser;

    const { originalPicture, profilePicture } = pictures ?? {};

    const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

    setProfileImageUrl(String(image));

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: `${String(firstName)} ${String(lastName)}`,
          name: String(email),
          iconId: 'id_enter_icon',
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      iconId: 'id_logout_icon'
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, profileUrl]);

  if (loggedUser) return (
    <>
      <Dropdown
        dropdownId="profile-dropdown"
        isDisplayContent={isSearchContentVisible}
        trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={`${loggedUser.firstName} ${loggedUser.lastName}`} />}
        onDisplayContent={setSearchIsContentVisible}
      >
        <UserNavigation
          dropdownNavigationItems={navigationList}
          footerConfig={footerConfig}
        />

      </Dropdown>
    </>
  );

  return <></>;


};

export { UserAccountMenu };
