import { gql } from '@apollo/client';

const ADD_CONDITION_GROUP_MUTATION = gql`
  mutation AddConditionGroup($scenarioId: Int!, $operator: ELogicOperator) {
    conditionGroups {
      add(scenarioId: $scenarioId, operator: $operator) {
        conditions {
          id
        }
        id
        operator
        sort
      }
    }
  }
`;

export { ADD_CONDITION_GROUP_MUTATION };
