import React from 'react';

import { Input, Label } from '@netfront/ui-library';

import { ScheduleTabMonthlyProps } from './ScheduleTabMonthly.interfaces';

import { scenarioScheduleTabConstants } from '../../ScenarioScheduleTab.constants';

const ScheduleTabMonthly = ({ schedule, onUpdateScenarioInput }: ScheduleTabMonthlyProps) => {
  const { recurrenceMinNumber, recurrenceMaxNumberDay } = scenarioScheduleTabConstants;

  return (
    <div className="c-schedule-tab-monthly">
      <Label forId="monthly" labelText="Recurrence pattern" />
      <div className="c-scenario-sidebar-schedule-view__occurrence-input-container">
        <span>Recur every</span>
        <Input
          additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
          id="day"
          labelText="Day"
          maxNumber={recurrenceMaxNumberDay}
          minNumber={recurrenceMinNumber}
          name="day"
          type="number"
          value={schedule?.day}
          isLabelHidden
          isRequired
          onChange={onUpdateScenarioInput}
        />

        <span>day of every</span>

        <Input
          additionalClassNames="c-scenario-sidebar-schedule-view__occurrence-input"
          id="occurEvery"
          labelText="occurEvery"
          minNumber={recurrenceMinNumber}
          name="occurEvery"
          type="number"
          value={schedule?.occurEvery}
          isLabelHidden
          isRequired
          onChange={onUpdateScenarioInput}
        />

        <span>months</span>
      </div>
    </div>
  );
};

export { ScheduleTabMonthly };
