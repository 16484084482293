// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { DELETE_SCHEDULE_MUTATION } from './useDeleteSchedule.graphql';
import {
  IDeleteScheduleMutationGraphQLResponse,
  IDeleteScheduleMutationVariables,
  IHandleDeleteScheduleParams,
  IUseDeleteScheduleOptions,
  IUseDeleteSchedule,
} from './useDeleteSchedule.interfaces';

const useDeleteSchedule = (options?: IUseDeleteScheduleOptions): IUseDeleteSchedule => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteScheduleOptions);

  const [executeDeleteSchedule, { loading: isLoading }] = useCapuchinMutation<
    IDeleteScheduleMutationGraphQLResponse,
    IDeleteScheduleMutationVariables
  >({
    mutation: mutation ?? DELETE_SCHEDULE_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          schedule: { removeSchedule: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSchedule = ({ scenarioId }: IHandleDeleteScheduleParams) => {
    void executeDeleteSchedule({
      variables: {
        scenarioId,
      },
    });
  };

  return {
    handleDeleteSchedule,
    isLoading,
  };
};

export { useDeleteSchedule };
