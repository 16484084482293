const AB_TOGGLE_ALERT_VISIBILITY_IN_SECONDS = 5;

const HOUR_TO_SECONDS = 3600;

const MINUTES_TO_SECONDS = 60;


const conditionFormViewConstants = Object.freeze({
  hourToSeconds: HOUR_TO_SECONDS,
  minutesToSeconds: MINUTES_TO_SECONDS,
  conditionTriggerTypeInformationVisibilityInSeconds: AB_TOGGLE_ALERT_VISIBILITY_IN_SECONDS,
});

export { conditionFormViewConstants };
