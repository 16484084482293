const INACTIVE_SCHEDULE_TAB_DEFAULT_NUMBER_INPUT_VALUE = 0;

const OCCURRENCE_MIN_NUMBER = 2;

const RECURRENCE_MIN_NUMBER = 1;

const RECURRENCE_MAX_NUMBER_DAY = 30;

const RECURRENCE_MAX_NUMBER_MONTH = 12;

const MAX_DATE_ADDITIONAL_YEAR = 5;

const DAY_TO_SECONDS = 86400;

const WEEK_TO_SECONDS = 604800;

const MONTH_TO_SECONDS = 2629743;

const YEAR_TO_SECONDS = 31556926;

const SCHEDULE_DAY_OPTIONS = [
  { labelText: 'Monday', id: 'MONDAY', value: 'MONDAY' },
  { labelText: 'Tuesday', id: 'TUESDAY', value: 'TUESDAY' },
  { labelText: 'Wednesday', id: 'WEDNESDAY', value: 'WEDNESDAY' },
  { labelText: 'Thursday', id: 'THURSDAY', value: 'THURSDAY' },
  { labelText: 'Friday', id: 'FRIDAY', value: 'FRIDAY' },
  { labelText: 'Saturday', id: 'SATURDAY', value: 'SATURDAY' },
  { labelText: 'Sunday', id: 'SUNDAY', value: 'SUNDAY' },
];

const SCHEDULE_MONTH_OPTIONS = [
  { id: 1, name: 'January', value: 1 },
  { id: 2, name: 'February', value: 2 },
  { id: 3, name: 'March', value: 3 },
  { id: 4, name: 'April', value: 4 },
  { id: 5, name: 'May', value: 5 },
  { id: 6, name: 'June', value: 6 },
  { id: 7, name: 'July', value: 7 },
  { id: 8, name: 'August', value: 8 },
  { id: 9, name: 'September', value: 9 },
  { id: 10, name: 'October', value: 10 },
  { id: 11, name: 'November', value: 11 },
  { id: 12, name: 'December', value: 12 },
];

const scheduleTabsetSelectionTypeMap = Object.freeze({
  ScheduleDailyGraphType: 'id_daily',
  ScheduleWeeklyGraphType: 'id_weekly',
  ScheduleMonthlyGraphType: 'id_monthly',
  ScheduleYearlyGraphType: 'id_yearly',
});

const scheduleActiveTabScheduleGraphSelectionTypeMap = Object.freeze({
  'id_daily': 'ScheduleDailyGraphType',
  'id_weekly': 'ScheduleWeeklyGraphType',
  'id_monthly': 'ScheduleMonthlyGraphType',
  'id_yearly': 'ScheduleYearlyGraphType',
});

const scheduleDayCountOfTheMonthTypeMap = Object.freeze({
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
});

const scheduleNotificationSecondsTypeMap = Object.freeze({
  delayBetweenNotificationInDay: DAY_TO_SECONDS,
  delayBetweenNotificationInWeek: WEEK_TO_SECONDS,
  delayBetweenNotificationInMonth: MONTH_TO_SECONDS,
  delayBetweenNotificationInYear: YEAR_TO_SECONDS,
});

const scenarioScheduleTabConstants = Object.freeze({
  inactiveScheduleTabDefaultNumberValue: INACTIVE_SCHEDULE_TAB_DEFAULT_NUMBER_INPUT_VALUE,
  maxDateAdditionalYear: MAX_DATE_ADDITIONAL_YEAR,
  occurrenceMinNumber: OCCURRENCE_MIN_NUMBER,
  recurrenceMaxNumberDay: RECURRENCE_MAX_NUMBER_DAY,
  recurrenceMaxNumberMonth: RECURRENCE_MAX_NUMBER_MONTH,
  recurrenceMinNumber: RECURRENCE_MIN_NUMBER,
  scheduleDayOptions: SCHEDULE_DAY_OPTIONS,
  scheduleMonthOptions: SCHEDULE_MONTH_OPTIONS,
  scheduleDayCountOfTheMonthTypeMap,
  scheduleNotificationSecondsTypeMap,
  scheduleTabsetSelectionTypeMap,
  scheduleActiveTabScheduleGraphSelectionTypeMap,
});

export { scenarioScheduleTabConstants, scheduleNotificationSecondsTypeMap };
