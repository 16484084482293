import React, { ChangeEvent, useState } from 'react';

import { Dialog, Input, SidebarButtons, Spacing, Spinner, Textarea, ToggleSwitch } from '@netfront/ui-library';

import { scenarioGeneralTabConstants } from './ScenarioGeneralTab.constants';
import { ScenarioGeneralTabProps } from './ScenarioGeneralTab.interfaces';

import { useAddScenario, useDeleteScenario, useToast, useUpdateScenario } from '../../../../hooks';
import { IDBScenario } from '../../../../interfaces';

const ScenarioGeneralTab = ({
  isAddNewScenarioVisible,
  projectId,
  scenario,
  onClose,
  onCreated,
  onDeleted,
  onOpen,
  onUpdated,
}: ScenarioGeneralTabProps) => {
  const { handleToastError } = useToast();

  const { descriptionCharacterMaxLength, titleCharacterMaxLength } = scenarioGeneralTabConstants;

  const { isTestPhase: isInitialTestPhase = false, status: initialStatus, overrideReceiver: initialOverrideReceiver } = scenario ?? {};

  const [currentScenario, setCurrentScenario] = useState<IDBScenario | undefined>(scenario);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isScenarioStatusActive, setIsScenarioStatusActive] = useState<boolean>(initialStatus === 'ACTIVE');
  const [isScenarioTestPhaseActive, setIsScenarioTestPhaseActive] = useState<boolean>(isAddNewScenarioVisible ? false : isInitialTestPhase);
  const [isOverrideReceiver, setIsOverrideReceiver] = useState<boolean>(Boolean(initialOverrideReceiver));

  const { description = '', id: scenarioId = 0, isTestPhase = isInitialTestPhase, status = 'INACTIVE', title = '', overrideReceiver = '' } = currentScenario ?? {};

  const { handleAddScenario: executeAddScenario, isLoading: isAddScenarioLoading = false } = useAddScenario({
    onCompleted: ({ scenario: returnedScenario }) => {
      onCreated(returnedScenario);
      onOpen(returnedScenario);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteScenario: executeDeleteScenario, isLoading: isDeleteScenarioLoading = false } = useDeleteScenario({
    onCompleted: () => {
      if (!currentScenario) {
        return;
      }

      onDeleted(currentScenario.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateScenario: executeUpdateScenario, isLoading: isUpdateScenarioLoading = false } = useUpdateScenario({
    onCompleted: ({ scenario: returnedScenario }) => {
      onUpdated(returnedScenario);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddScenario = () => {
    executeAddScenario({
      projectId,
      description,
      isTestPhase: isScenarioTestPhaseActive,
      shouldIncludeConditions: false,
      title,
      overrideReceiver: isOverrideReceiver ? overrideReceiver : null,
    });
  };

  const handleDeleteScenario = () => {
    if (!scenarioId) {
      return;
    }

    executeDeleteScenario({
      scenarioId,
    });
  };

  const handleUpdateScenarioInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentScenario(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBScenario),
    );
  };

  const handleUpdateScenarioStatus = () => {
    setIsScenarioStatusActive(!isScenarioStatusActive);

    setCurrentScenario({
      ...currentScenario,
      status: !isScenarioStatusActive ? 'ACTIVE' : 'INACTIVE',
    } as IDBScenario);
  };

  const handleUpdateScenarioTestPhase = () => {
    setIsScenarioTestPhaseActive(!isScenarioTestPhaseActive);

    setCurrentScenario({
      ...currentScenario,
      isTestPhase: !isScenarioTestPhaseActive ? true : false,
    } as IDBScenario);
  };

  const handleUpdateIsOverrideReceiver = () => {
    setCurrentScenario({
      ...currentScenario,
      overrideReceiver: '',
    } as IDBScenario);

    setIsOverrideReceiver(!isOverrideReceiver);
  };

  const handleUpdateScenario = () => {
    executeUpdateScenario({
      description,
      id: scenarioId,
      isTestPhase,
      shouldIncludeConditions: false,
      status,
      title,
      overrideReceiver: isOverrideReceiver ? overrideReceiver : null,
    });
  };

  const isLoading = isAddScenarioLoading || isDeleteScenarioLoading || isUpdateScenarioLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <>
        {!isAddNewScenarioVisible ? (
          <Spacing size="large">
            <ToggleSwitch
              id="status"
              isChecked={isScenarioStatusActive}
              labelText="Activated"
              onChange={handleUpdateScenarioStatus}
            />
          </Spacing>) : null}
        <Spacing size="large">
          <Input
            id="title"
            labelText="Title"
            maxLength={titleCharacterMaxLength}
            name="title"
            type="text"
            value={title}
            onChange={handleUpdateScenarioInput}
          />
        </Spacing>

        <Spacing>
          <Textarea
            id="description"
            labelText="Description"
            maxLength={descriptionCharacterMaxLength}
            name="description"
            value={description}
            onChange={handleUpdateScenarioInput}
          />
        </Spacing>

        <Spacing>
          <ToggleSwitch
            id="isTestPhase"
            isChecked={isScenarioTestPhaseActive}
            labelText="Test mode"
            tooltipPosition="end"
            tooltipText="If the test group is activated, all scheduled notifications will be sent to the active test group instead of the associated user"
            onChange={handleUpdateScenarioTestPhase}
          />
        </Spacing>

        <Spacing>
          <ToggleSwitch
            id="isOverrideReceiver"
            isChecked={isOverrideReceiver}
            labelText="Override receiver"
            tooltipPosition="end"
            tooltipText="Add an email or distribution list key here to override who receives the email when this scenario is triggered"
            onChange={handleUpdateIsOverrideReceiver}
          />
        </Spacing>

        {isOverrideReceiver && (
          <Spacing size="large">
            <Input
              id="id_overrideReceiver"
              labelText="Email or distribution key to notify"
              name="overrideReceiver"
              type="text"
              value={overrideReceiver}
              onChange={handleUpdateScenarioInput}
            />
          </Spacing>
        )}

        {!isAddNewScenarioVisible ? (
          <Dialog
            isOpen={isDeleteDialogOpen}
            title={`Delete scenario: ${title}?`}
            isNarrow
            onCancel={() => setIsDeleteDialogOpen(false)}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDeleteScenario}
          />
        ) : null}

        <SidebarButtons
          onCancel={onClose}
          onDelete={!isAddNewScenarioVisible ? () => setIsDeleteDialogOpen(true): undefined}
          onSave={!isAddNewScenarioVisible ? handleUpdateScenario : handleAddScenario}
        />
      </>
    </>
  );
};

export { ScenarioGeneralTab };
