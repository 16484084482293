import { gql } from '@apollo/client';

const GET_INVITATION_REMINDER = gql`
  query getInvitationReminder($id: Int!) {
    invitationReminders {
      get(id: $id) {
        delay
        id
        start
        end
        description
        templateId
        groupId
        template {
          id
          title
          type
        }
        title
      }
    }
  }
`;

export { GET_INVITATION_REMINDER };
