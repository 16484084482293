import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { UPDATE_INVITATION_REMINDER } from './useUpdateInvitationReminder.graphql';
import {
  IUpdateInvitationReminderMutationGraphQLResponse,
  IUpdateInvitationReminderMutationVariables,
  IHandleUpdateInvitationReminderParams,
  IUseUpdateInvitationReminderOptions,
  IUseUpdateInvitationReminder,
} from './useUpdateInvitationReminder.interfaces';

const useUpdateInvitationReminder = (options?: IUseUpdateInvitationReminderOptions): IUseUpdateInvitationReminder => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateInvitationReminderOptions);

  const [executeUpdateInvitationReminder, { loading: isLoading }] = useCapuchinMutation<
  IUpdateInvitationReminderMutationGraphQLResponse,
  IUpdateInvitationReminderMutationVariables
  >({
    mutation: mutation ?? UPDATE_INVITATION_REMINDER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          invitationReminders: { update: invitationReminder },
        } = data;

        onCompleted({
          invitationReminder,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateInvitationReminder = ({
    id,
    description,
    title,
    start,
    end,
    delay,
    templateId,
    groupId,
  }: IHandleUpdateInvitationReminderParams) => {
    void executeUpdateInvitationReminder({
      variables: {
        request: {
          description,
          title,
          start,
          end,
          delay,
          templateId,
          groupId,
        },
        id,
      },
    });
  };

  return {
    handleUpdateInvitationReminder,
    isLoading,
  };
};

export { useUpdateInvitationReminder };
