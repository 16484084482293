import { gql } from '@apollo/client';

const COPY_CONDITION_MUTATION = gql`
  mutation CopyCondition($conditionGroupId: Int!, $conditionId: Int!) {
    conditions {
      copy(conditionId: $conditionId, id: $conditionGroupId)
    }
  }
`;

export { COPY_CONDITION_MUTATION };
