import React, { ChangeEvent, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBUserFlowStep, IUseGetUserFlowStepsOnCompletedResponse, useGetUserFlowSteps } from '@netfront/ekardo-content-library';
import {
  Dialog,
  Input,
  ISearchWithButtonListItem,
  NavigationButton,
  Preloader,
  Select,
  SearchWithButtonList,
  Spacing,
  Spinner,
  Textarea,
  ToggleSwitch,
  Tooltip,
  SidebarButtons,
  InformationBox,
} from '@netfront/ui-library';
import { useDebouncedCallback } from 'use-debounce';

import { scenarioConditionTabConstants } from '../ScenarioConditionTab.constants';

import {
  ConditionFormDayTypeView,
  ConditionFormHourTypeView,
  ConditionFormStepCompletedTypeView,
  ConditionFormStepStartedTypeView,
  ConditionFormUserActivatedTypeView,
  ConditionFormUserLoginTypeView,
  ConditionFormUserRegisteredTypeView,
  ConditionFormUserLastLoginTypeView,
  ConditionFormUserSessionTypeView,
  ConditionFormStepProgressionTypeView,
  ConditionFormUserGroupTypeView,
} from './ConditionFormTypeViews';
import { conditionFormViewConstants } from './ConditionFormView.constants';
import { getConditionTypeSelectOptions } from './ConditionFormView.helpers';
import { ConditionFormViewProps } from './ConditionFormView.interfaces';

import {
  useAddCondition,
  useCopyCondition,
  useDeleteCondition,
  useGetPaginatedConditions,
  useUpdateCondition,
  useToast,
} from '../../../../../hooks';
import { IDBCondition } from '../../../../../interfaces';
import { DBConditionType } from '../../../../../types';

const ConditionFormView = ({
  projectId,
  scenarioId,
  selectedCondition,
  selectedConditionGroupId,
  onGetConditionGroups,
  onOpenConditionForm,
}: ConditionFormViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const { hourToSeconds, minutesToSeconds } = conditionFormViewConstants;

  const {
    conditionSelectTypes,
    conditionTypeMap,
    conditionTypeMessageMap,
    debounceQueryWaitTimeInMilliseconds,
    descriptionCharacterMaxLength,
    titleCharacterMaxLength,
  } = scenarioConditionTabConstants;

  const { stepId, __typename = '' } = selectedCondition ?? {};

  const [currentCondition, setCurrentCondition] = useState<IDBCondition>();
  const [conditionStepId, setConditionStepId] = useState<number | undefined>(stepId);
  const [conditionStepName, setConditionStepName] = useState<string>();
  const [conditionType, setConditionType] = useState<DBConditionType>(conditionTypeMap[__typename] as DBConditionType);
  const [conditionsToAttach, setConditionsToAttach] = useState<IDBCondition[]>([]);
  const [allUserFlowSteps, setAllUserFlowSteps] = useState<IDBUserFlowStep[]>();
  const [isCopyConditionVisible, setIsCopyConditionVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const {
    comparator,
    id: conditionId,
    day,
    delay,
    description = '',
    percentage,
    title = '',
    value: hour,
    valueComparator,
    groupId,
  } = currentCondition ?? {};

  const { handleGetUserFlowSteps, isLoading: isGetUserFlowStepsLoading } = useGetUserFlowSteps({
    onCompleted: ({ userFlowSteps }: IUseGetUserFlowStepsOnCompletedResponse) => {
      setAllUserFlowSteps(userFlowSteps);

      const userFlowStep = userFlowSteps.find((userStep) => userStep.id === conditionStepId);
      setConditionStepName(userFlowStep?.stepName);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleAddCondition: executeAddCondition, isLoading: isAddConditionLoading = false } = useAddCondition({
    onCompleted: () => {
      onOpenConditionForm();

      onGetConditionGroups({
        scenarioId,
      });

      handleToastSuccess({
        message: 'Condition created successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCopyCondition, isLoading: isCopyConditionLoading = false } = useCopyCondition({
    onCompleted: () => {
      onOpenConditionForm();

      onGetConditionGroups({
        scenarioId,
      });

      handleToastSuccess({
        message: 'Condition copied successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteCondition: executeDeleteCondition, isLoading: isDeleteConditionLoading = false } = useDeleteCondition({
    onCompleted: () => {
      if (!currentCondition) {
        return;
      }

      onGetConditionGroups({
        scenarioId,
      });

      setIsDeleteDialogOpen(false);

      onOpenConditionForm();

      handleToastSuccess({
        message: 'Condition deleted successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetPaginatedConditions, isLoading: isGetPaginatedConditionsLoading = false } = useGetPaginatedConditions({
    onCompleted: ({ conditionConnection: { edges } }) => {
      const conditions = edges.map(({ node }) => node);

      setConditionsToAttach(conditions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDebounceGetPaginatedConditionsQuery = useDebouncedCallback((inputText) => {
    handleGetPaginatedConditions({
      projectId,
      filter: inputText,
      scenarioId,
    });
  }, Number(debounceQueryWaitTimeInMilliseconds));

  const handleAttachCondition = (attachedCondition: ISearchWithButtonListItem | null) => {
    if (!attachedCondition) {
      return;
    }

    handleCopyCondition({
      conditionGroupId: Number(selectedConditionGroupId),
      conditionId: attachedCondition.id,
    });
  };

  const handleRecommendedSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value.length <= 2) {
      return;
    }

    handleDebounceGetPaginatedConditionsQuery(value);
  };

  const { handleUpdateCondition: executeUpdateCondition, isLoading: isUpdateConditionLoading = false } = useUpdateCondition({
    onCompleted: () => {
      onOpenConditionForm();

      onGetConditionGroups({
        scenarioId,
      });

      handleToastSuccess({
        message: 'Condition updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpdateConditionInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    if (name === 'value') {
      const times = value.split(':');
      const hours = Number(times[0]);
      const minutes = Number(times[1]);

      const seconds = hours * hourToSeconds + minutes * minutesToSeconds;

      setCurrentCondition(
        (currentState: IDBCondition | undefined) =>
          ({
            ...currentState,
            value: seconds,
          } as IDBCondition),
      );

      return;
    }

    setCurrentCondition(
      (currentState: IDBCondition | undefined) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBCondition),
    );
  };

  const handleUpdateUserGroupConditionInput = ({name, value}: {name: string, value: number}) => {
    setCurrentCondition(
      (currentState: IDBCondition | undefined) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBCondition),
    );
  };

  const handleUpdateConditionDelayInput = ({name, value}: {name: string, value: number}) => {
    setCurrentCondition(
      (currentState: IDBCondition | undefined) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBCondition),
    );
  };

  const handleUpdateConditionTypeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setConditionType(value as DBConditionType);
  };

  const handleUpdateConditionStepSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setConditionStepName(value);

    const currentUserFlowStep = allUserFlowSteps?.find((userFlowStep) => userFlowStep.stepName === value);
    setConditionStepId(currentUserFlowStep?.id);
  };

  const mutationVariablesDefault = {
    conditionGroupId: selectedConditionGroupId,
    delay: Number(delay),
    description,
    title,
  };

  const handleAddCondition = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    executeAddCondition({
      [conditionType]: conditionTypeMutationVariablesMap[conditionType],
    });
  };

  const handleDeleteCondition = () => {
    if (!conditionId) {
      return;
    }

    executeDeleteCondition({
      conditionId,
    });
  };

  const handleUpdateCondition = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    executeUpdateCondition({
      conditionId,
      [conditionType]: conditionTypeMutationVariablesMap[conditionType],
    });
  };

  useEffect(() => {
    void handleGetUserFlowSteps({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conditionTypeMutationVariablesMap = {
    day: {
      ...mutationVariablesDefault,
      delay: 0,
      day,
    },
    hour: {
      ...mutationVariablesDefault,
      delay: 0,
      value: Number(hour),
    },
    stepStarted: {
      ...mutationVariablesDefault,
      comparator,
      stepId: conditionStepId,
    },
    stepCompleted: {
      ...mutationVariablesDefault,
      comparator,
      stepId: conditionStepId,
    },
    stepProgression: {
      ...mutationVariablesDefault,
      comparator,
      stepId: conditionStepId,
      percentage: Number(percentage),
      valueComparator,
    },
    userActivated: {
      ...mutationVariablesDefault,
      comparator,
    },
    userLastLogin: mutationVariablesDefault,
    ...mutationVariablesDefault,
    userLogin: {
      ...mutationVariablesDefault,
      comparator,
    },
    userGroup: {
      ...mutationVariablesDefault,
      comparator,
      delay: 0,
      groupId,
    },
    userRegistered: mutationVariablesDefault,
    ...mutationVariablesDefault,
    userSession: {
      ...mutationVariablesDefault,
      comparator,
    },
  };

  const conditionTypeViewMap = {
    day: <ConditionFormDayTypeView currentCondition={currentCondition} onUpdateConditionInput={handleUpdateConditionInput} />,
    hour: <ConditionFormHourTypeView currentCondition={currentCondition} onUpdateConditionInput={handleUpdateConditionInput}/>,
    stepStarted:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepStartedTypeView
          conditionStepName={conditionStepName}
          currentCondition={currentCondition}
          userFlowSteps={allUserFlowSteps}
          onUpdateConditionDelayInput={handleUpdateConditionDelayInput}
          onUpdateConditionInput={handleUpdateConditionInput}
          onUpdateConditionStepSelect={handleUpdateConditionStepSelect}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    stepCompleted:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepCompletedTypeView
          conditionStepName={conditionStepName}
          currentCondition={currentCondition}
          userFlowSteps={allUserFlowSteps}
          onUpdateConditionDelayInput={handleUpdateConditionDelayInput}
          onUpdateConditionInput={handleUpdateConditionInput}
          onUpdateConditionStepSelect={handleUpdateConditionStepSelect}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    stepProgression:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepProgressionTypeView
          conditionStepName={conditionStepName}
          currentCondition={currentCondition}
          userFlowSteps={allUserFlowSteps}
          onUpdateConditionDelayInput={handleUpdateConditionDelayInput}
          onUpdateConditionInput={handleUpdateConditionInput}
          onUpdateConditionStepSelect={handleUpdateConditionStepSelect}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    userActivated: (
      <ConditionFormUserActivatedTypeView currentCondition={currentCondition} onUpdateConditionDelayInput={handleUpdateConditionDelayInput} onUpdateConditionInput={handleUpdateConditionInput}/>
    ),
    userLastLogin: (
      <ConditionFormUserLastLoginTypeView currentCondition={currentCondition} onUpdateConditionDelayInput={handleUpdateConditionDelayInput}/>
    ),
    userLogin: <ConditionFormUserLoginTypeView currentCondition={currentCondition} onUpdateConditionDelayInput={handleUpdateConditionDelayInput} onUpdateConditionInput={handleUpdateConditionInput}/>,
    userGroup: (
      <ConditionFormUserGroupTypeView
        currentCondition={currentCondition}
        projectId={projectId}
        onUpdateConditionUserGroupInput={handleUpdateUserGroupConditionInput}
      />
    ),
    userRegistered: (
      <ConditionFormUserRegisteredTypeView currentCondition={currentCondition} onUpdateConditionDelayInput={handleUpdateConditionDelayInput}/>
    ),
    userSession: (
      <ConditionFormUserSessionTypeView currentCondition={currentCondition} onUpdateConditionDelayInput={handleUpdateConditionDelayInput} onUpdateConditionInput={handleUpdateConditionInput}/>
    ),
  };

  useEffect(() => {
    if (!selectedCondition) {
      return;
    }

    setCurrentCondition(selectedCondition);

    conditionTypeViewMap[selectedCondition.__typename];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondition]);


  const isLoading = isAddConditionLoading || isDeleteConditionLoading || isUpdateConditionLoading;

  return (
    <div className="c-condition-form-view">
      <Spinner isLoading={isLoading} />
      <Spacing size="2x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <NavigationButton
            additionalClassNames="c-action__back-to-action"
            direction="previous"
            iconId="id_caret_icon"
            rotationCssSuffix="90"
            text="Condition"
            onClick={onOpenConditionForm}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Configure the scenario trigger condition"
          />
        </section>
      </Spacing>

      {!selectedCondition && (
        <Spacing size="4x-large">
          <ToggleSwitch
            id="copy-condition"
            isChecked={isCopyConditionVisible}
            labelText="Copy existing condition"
            onChange={() => setIsCopyConditionVisible(!isCopyConditionVisible)}
          />
        </Spacing>
      )}

      {isCopyConditionVisible ? (
        <Spacing size="2x-large">
          <section className="c-scenario-sidebar-condition-view__search-section">
            <Tooltip
              additionalClassNames="c-scenario-sidebar-condition-view__tooltip"
              bubbleTheme="dark"
              iconId="id_tooltip_icon"
              placement="left"
              text="Search and copy existing condition"
            />

            <SearchWithButtonList
              labelText="Copy existing condition"
              listItems={conditionsToAttach}
              onChange={handleRecommendedSearchInput}
              onClick={handleAttachCondition}
            />

            <Preloader isLoading={isCopyConditionLoading || isGetPaginatedConditionsLoading} />
          </section>
        </Spacing>
      ) : (
        <form className="c-condition-form-view_form" onSubmit={selectedCondition ? handleUpdateCondition : handleAddCondition}>
          <Spacing size="large">
            <Input
              id="title"
              labelText="Title"
              maxLength={titleCharacterMaxLength}
              name="title"
              type="text"
              value={title}
              isRequired
              onChange={handleUpdateConditionInput}
            />
          </Spacing>

          <Spacing size="large">
            <Textarea
              id="description"
              labelText="Description"
              maxLength={descriptionCharacterMaxLength}
              name="description"
              value={description}
              onChange={handleUpdateConditionInput}
            />
          </Spacing>

          <Spacing size="x-large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <div className="c-condition-form-view__condition-type-select">
                <Select
                  id="condition-type"
                  isDisabled={Boolean(selectedCondition)}
                  labelText="Trigger"
                  name="conditionType"
                  options={getConditionTypeSelectOptions(conditionSelectTypes)}
                  value={conditionType}
                  isRequired
                  onChange={handleUpdateConditionTypeSelect}
                />

                {Boolean(conditionType) && (
                  <InformationBox
                    headingTagComponent="span"
                    iconId="id_info_icon"
                    message={conditionTypeMessageMap[conditionType]}
                  />
                )}

              </div>

              <Tooltip
                additionalClassNames="c-scenario-sidebar__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Define a trigger condition"
              />
            </section>
          </Spacing>

          {conditionTypeViewMap[conditionType]}

          {selectedCondition ? (
            <Dialog
              isOpen={isDeleteDialogOpen}
              title={`Delete condition: ${title}?`}
              isNarrow
              onCancel={() => setIsDeleteDialogOpen(false)}
              onClose={() => setIsDeleteDialogOpen(false)}
              onConfirm={handleDeleteCondition}
            />
          ) : null}

          <SidebarButtons
            onCancel={onOpenConditionForm}
            onDelete={selectedCondition ? () => setIsDeleteDialogOpen(true) : undefined}
            onSaveButtonType="submit"
          />
        </form>
      )}
    </div>
  );
};

export { ConditionFormView };
