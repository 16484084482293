import { ChangeEvent } from 'react';

import { IDBUserFlowStep } from '@netfront/ekardo-content-library';
import { IOption } from '@netfront/ui-library';
import capitalize from 'lodash/capitalize';

import { IConditionTypeOptions } from './ConditionFormView.interfaces';

const getConditionTypeSelectOptions = (data: readonly IConditionTypeOptions[]): IOption[] => {
  return data.map((item) => {
    const { id, label, value } = item;

    const option: IOption = {
      id,
      name: capitalize(label),
      value,
    };

    return option;
  }).sort((a, b) => a.name.localeCompare(b.name))
};

const getConditionStepSelectOptions = (data: readonly IDBUserFlowStep[]): IOption[] => {
  return data.map((item) => {
    const { id, stepName } = item;

    const option: IOption = {
      id,
      name: capitalize(stepName),
      value: stepName,
    };

    return option;
  }).sort((a, b) => a.name.localeCompare(b.name));
};

const createSyntheticEvent = (name: string, value: string): ChangeEvent<HTMLInputElement> => {
  return {
    target: { name, value } as EventTarget & HTMLInputElement,
  } as ChangeEvent<HTMLInputElement>;
};

export { getConditionTypeSelectOptions, getConditionStepSelectOptions, createSyntheticEvent };
