import { gql } from '@apollo/client';

import { SCENARIO_FRAGMENT } from '../../../fragments';

const GET_SCENARIOS_QUERY = gql`
  query GetScenarios(
    $after: String = null
    $filter: String = null
    $first: Int = null
    $projectId: String!
    $shouldIncludeConditions: Boolean!
    $status: EScenarioStatus!
  ) {
    scenarioQueries {
      getScenarios(after: $after, first: $first, filter: $filter, projectId: $projectId, status: $status) {
        edges {
          cursor
          node {
            ...scenarioFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }

  ${SCENARIO_FRAGMENT}
`;

export { GET_SCENARIOS_QUERY };
