import ReactGA from 'react-ga';

const PageView = (url: string, userId: string) => {
  if (userId) {
    ReactGA.initialize(String(process.env.REACT_APP_GOOGLE_ANALYTICS), {
      gaOptions: {
        userId: String(userId),
      },
    });
    ReactGA.pageview(url);
  } else {
    ReactGA.pageview(url);
  }
};

export { PageView };
