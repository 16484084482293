const AB_TOGGLE_ALERT_VISIBILITY_IN_SECONDS = 3;

const COUNTER_STEP_VALUE = 1;

const DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS = process.env.REACT_APP_API_QUERY_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS;

const INPUT_COUNTER_MAX_VALUE = 100;

const TEMPLATE_TOTAL_PERCENTAGE = 100;

const TEMPLATE_MIN_PERCENTAGE = 0;

const INPUT_COUNTER_MIN_VALUE = 1;

const scenarioTemplatesTabConstants = Object.freeze({
  abToggleAlertVisibilityInSeconds: AB_TOGGLE_ALERT_VISIBILITY_IN_SECONDS,
  counterStepValue: COUNTER_STEP_VALUE,
  debounceQueryWaitTimeInMilliseconds: DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS,
  inputCounterMaxValue: INPUT_COUNTER_MAX_VALUE,
  inputCounterMinValue: INPUT_COUNTER_MIN_VALUE,
  templateMinPercentage: TEMPLATE_MIN_PERCENTAGE,
  templateTotalPercentage: TEMPLATE_TOTAL_PERCENTAGE,
});

export { scenarioTemplatesTabConstants };
