import { useEffect, useState, useContext } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation, OrganisationNavigationItemProps } from '@netfront/ui-library';

import { CachingEntitiesContext } from '../../context';

const OrganisationMenu = () => {
  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();

  const { project } = useContext(CachingEntitiesContext);
  const [navigationItems, setNavigationItems] = useState<OrganisationNavigationItemProps[]>();

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();
    if (!accessToken) return;

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;
    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenCookie]);

  useEffect(() => {
    if (!(project && identityUrl)) return;

    const { organisation } = project;

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: !organisation,
        header: {
          title: `Current account`,
          name: organisation.name,
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_team_icon',
            label: 'Team',
            href: !isCustomBuild ? `${identityUrl}/account/${String(organisation.key)}/team`: undefined,
          },
        ],
      },
      {
        isHidden: !project,
        header: {
          title: `Current project`,
          name: project.name,
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_users_icon',
            label: 'Users',
            isHidden: !project,
            href: `${identityUrl}/project/${String(project.id)}/user`
          },
        ],
      },
    ];

    setNavigationItems(dropdownNavigationItems);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isCustomBuild, identityUrl]);

  return (
    <div className="c-organisation-menu">
      {project && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Icon className="c-app-header__item" id="id_organisation_icon" />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <OrganisationNavigation
            dropdownNavigationItems={navigationItems}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
