// TODO: Move this to @netfront/capuchin-notifications-library

import { useCapuchinMutation } from '../capuchin/useCapuchinMutation';

import { UPDATE_CONDITION_MUTATION } from './useUpdateCondition.graphql';
import {
  IUpdateConditionMutationGraphQLResponse,
  IUpdateConditionMutationVariables,
  IHandleUpdateConditionParams,
  IUseUpdateConditionOptions,
  IUseUpdateCondition,
} from './useUpdateCondition.interfaces';

const useUpdateCondition = (options?: IUseUpdateConditionOptions): IUseUpdateCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateConditionOptions);

  const [executeUpdateCondition, { loading: isLoading }] = useCapuchinMutation<
  IUpdateConditionMutationGraphQLResponse,
  IUpdateConditionMutationVariables
  >({
    mutation: mutation ?? UPDATE_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { update: condition },
        } = data;

        onCompleted(condition);
      },
      onError,
    },
    token,
  });

  const handleUpdateCondition = ({
    conditionId,
    day,
    hour,
    stepStarted,
    stepCompleted,
    stepProgression,
    userActivated,
    userLogin,
    userRegistered,
    userLastLogin,
    userSession,
    userGroup,
  }: IHandleUpdateConditionParams) => {
    void executeUpdateCondition({
      variables: {
        conditionId,
        day,
        hour,
        stepStarted,
        stepCompleted,
        stepProgression,
        userActivated,
        userLogin,
        userRegistered,
        userLastLogin,
        userSession,
        userGroup,
      },
    });
  };

  return {
    handleUpdateCondition,
    isLoading,
  };
};

export { useUpdateCondition };
