import { gql } from '@apollo/client';

const UPDATE_CONDITION_GROUP_MUTATION = gql`
  mutation UpdateConditionGroup($conditionGroupId: Int!, $operator: ELogicOperator!) {
    conditionGroups {
      update(conditionGroupId: $conditionGroupId, operator: $operator) {
        conditions {
          id
        }
        id
        operator
        sort
      }
    }
  }
`;

export { UPDATE_CONDITION_GROUP_MUTATION };
