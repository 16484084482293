/* eslint-disable import/no-duplicates */
import React from 'react';

import { Label, Spacing, Tooltip } from '@netfront/ui-library';

import { ConditionFormHourTypeViewProps } from './ConditionFormHourTypeView.interfaces';

import { conditionFormViewConstants } from '../../ConditionFormView.constants';

const ConditionFormHourTypeView = ({ currentCondition, onUpdateConditionInput }: ConditionFormHourTypeViewProps) => {
  const {  value: hour } = currentCondition ?? {};

  const { hourToSeconds, minutesToSeconds } = conditionFormViewConstants;

  const handleCalculateSecondsToHourAndMinutes = (seconds: number) => {
    const hours = Math.floor(seconds / hourToSeconds);
    const minutes = Math.floor((seconds - hours * hourToSeconds) / minutesToSeconds);

    const hourValue = hours < 10 ? `0${hours}` : hours;
    const minuteValue = minutes < 10 ? `0${minutes}` : minutes;

    return `${hourValue}:${minuteValue}`;
  };

  return (
    <div className="c-condition-form-hour-type-view">
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Label forId="hour" labelText="Condition hour" />

          <div className="c-condition-form-view__delay">
            <input
              className="c-input c-condition-form-view__hour-input"
              id="hour"
              name="value"
              type="time"
              value={handleCalculateSecondsToHourAndMinutes(Number(hour))}
              onChange={onUpdateConditionInput}
            />

            <span>Hours</span>
          </div>

          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Condition hour"
          />
        </section>
      </Spacing>
    </div>
  );
};

export { ConditionFormHourTypeView };
