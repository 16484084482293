import { gql } from '@apollo/client';

const GET_CURRENT_TEMPLATES_QUERY = gql`
  query GetCurrentTemplates($scenarioId: Int!) {
    scenarioQueries {
      getTemplates(id: $scenarioId) {
        percentage
        scenarioId
        template {
          id
          type
          title
        }
      }
    }
  }
`;

export { GET_CURRENT_TEMPLATES_QUERY };
