import { gql } from '@apollo/client';

const DELETE_TEST_USER_MUTATION = gql`
  mutation DeleteTestUser($projectId: String!, $userId: Int!) {
    projectTestUserMutations {
      removeUser(projectId: $projectId, userId: $userId)
    }
  }
`;

export { DELETE_TEST_USER_MUTATION };
