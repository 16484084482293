import React, { useEffect, useState } from 'react';

import {
  ITab,
  SideBarTabSet,
  ToggleSwitch,
} from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { TableTemplate } from 'components/Shared/TableTemplate';

import { SCENARIOS_TABLE_COLUMNS } from './UserScenariosTable.constants';
import { getScenariosTableData } from './UserScenariosTable.helpers';
import { IUserScenariosTableData } from './UserScenariosTable.interfaces';

import { useGetPaginatedScenarios, useToast } from '../../../../../../hooks';
import { IDBScenario, IDBSchedule } from '../../../../../../interfaces';
import { DBScenarioStatusType } from '../../../../../../types';
import {
  ScenarioConditionTab,
  ScenarioGeneralTab,
  ScenarioScheduleTab,
  ScenarioTemplatesTab,
} from '../../../../../Tabs';
import { USER_SCENARIO_PAGE_CONSTANTS } from '../../UserScenariosPage.constants';

const UserScenariosTable = () => {
  const { query } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { projectId } = query;
  const { defaultPageSize } = USER_SCENARIO_PAGE_CONSTANTS;

  const [allScenarios, setAllScenarios] = useState<IDBScenario[]>();
  const [filter, setFilter] = useState<string>();
  const [isAddNewScenario, setIsAddNewScenario] = useState<boolean>(false);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [scenariosTableData, setScenariosTableData] = useState<IUserScenariosTableData[]>();
  const [selectedScenario, setSelectedScenario] = useState<IDBScenario>();
  const [statusFilter, setStatusFilter] = useState<DBScenarioStatusType>('ACTIVE');
  const [totalScenarios, setTotalScenarios] = useState<number>(0);

  const {
    handleFetchMorePaginatedScenarios,
    handleGetPaginatedScenarios,
    isLoading: isGetPaginatedScenariosLoading = false,
  } = useGetPaginatedScenarios({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ scenarioConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const scenarios = edges.map(({ node }) => node);

      setAllScenarios(scenarios);
      setIsLoadMoreDisabled(scenarios.length >= totalCount || totalCount <= pageSize);
      setTotalScenarios(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewScenarioClick = () => {
    setIsAddNewScenario(true);
    setIsSideBarOpen(true);
  };

  const handleAddScenario = (scenario: IDBScenario) => {
    setAllScenarios((currentState): IDBScenario[] | undefined => {
      if (!currentState) {
        return;
      }

      return [...currentState, scenario];
    });

    setTotalScenarios(totalScenarios + 1);
    setStatusFilter('INACTIVE');
    handleSideBarClose();
    handleToastSuccess({
      message: 'Scenario created successfully',
    });
  };

  const handleDeletedScenario = (id: IDBScenario['id']) => {
    setAllScenarios((currentState): IDBScenario[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.filter((scenario) => {
        return scenario.id !== id;
      });
    });

    setTotalScenarios(totalScenarios - 1);

    handleSideBarClose();
    handleToastSuccess({
      message: 'Scenario deleted successfully',
    });
  };

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedScenarios({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
      shouldIncludeConditions: false,
      status: statusFilter,
    });

    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsAddNewScenario(false);
    setIsSideBarOpen(false);
    setSelectedScenario(undefined);
  };

  const handleSidebarOpen = (scenario: IDBScenario) => {
    setIsSideBarOpen(true);
    setSelectedScenario(scenario);
  };

  const handleScenarioStatusFilterChange = (status: DBScenarioStatusType) => {
    setStatusFilter(status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
  };

  const handleUpdatedScenario = (scenario: IDBScenario) => {
    setAllScenarios((currentState): IDBScenario[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentScenario): IDBScenario => {
        return currentScenario.id === scenario.id ? scenario : currentScenario;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Scenario updated successfully',
    });
  };

  const handleUpdatedScenarioSchedule = (schedule: IDBSchedule, scenario: IDBScenario) => {
    setAllScenarios((currentState): IDBScenario[] | undefined => {
      if (!currentState) {
        return;
      }

      return currentState.map((currentScenario): IDBScenario => {
        return currentScenario.id === scenario.id
          ? {
            ...scenario,
            schedule,
          }
          : currentScenario;
      });
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedScenarios({
      first: pageSize,
      projectId: String(projectId),
      shouldIncludeConditions: false,
      status: statusFilter,
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, statusFilter]);

  useEffect(() => {
    if (!allScenarios) {
      return;
    }

    setScenariosTableData(
      getScenariosTableData({
        scenarios: allScenarios,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedScenario(allScenarios.find(({ id: scenarioId }) => id === scenarioId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allScenarios]);

  const isLoading = isGetPaginatedScenariosLoading;

  const addNewScenarioTabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <ScenarioGeneralTab
          isAddNewScenarioVisible={isAddNewScenario}
          projectId={String(projectId)}
          scenario={!isAddNewScenario ? selectedScenario : undefined}
          onClose={handleSideBarClose}
          onCreated={handleAddScenario}
          onDeleted={handleDeletedScenario}
          onOpen={handleSidebarOpen}
          onUpdated={handleUpdatedScenario}
        />
      ),
    },
  ];

  const existingScenarioTabs: ITab[] = selectedScenario
    ? [
      {
        iconId: 'id_general_tab_icon',
        id: 'id_general_tab',
        label: 'General',
        view: () => (
          <ScenarioGeneralTab
            projectId={String(projectId)}
            scenario={selectedScenario}
            onClose={handleSideBarClose}
            onCreated={handleAddScenario}
            onDeleted={handleDeletedScenario}
            onOpen={handleSidebarOpen}
            onUpdated={handleUpdatedScenario}
          />
        ),
      },
      {
        iconId: 'id_units_tab_icon',
        id: 'id_condition_tab',
        label: 'Condition',
        view: () => (
          <ScenarioConditionTab projectId={String(projectId)} scenarioId={selectedScenario.id} onClose={handleSideBarClose} />
        ),
      },
      {
        iconId: 'id_schedule_icon',
        id: 'id_schedule_tab',
        label: 'Schedule',
        view: () => (
          <ScenarioScheduleTab
            scenario={selectedScenario}
            onClose={handleSideBarClose}
            onUpdated={handleUpdatedScenario}
            onUpdatedScenarioSchedule={handleUpdatedScenarioSchedule}
          />
        ),
      },
      {
        iconId: 'id_notes_tab_icon',
        id: 'id_templates_test_tab',
        label: 'Templates',
        view: () => (
          <ScenarioTemplatesTab projectId={String(projectId)} scenarioId={selectedScenario.id} onClose={handleSideBarClose} />
        ),
      },
    ]
    : [];

  const tabs = isAddNewScenario ? addNewScenarioTabs : existingScenarioTabs;

  return (
    <>
      <TableTemplate<IUserScenariosTableData>
        childrenEnd={
          <ToggleSwitch
            additionalClassNames="c-scenario-status-toggle"
            id="scenario-status-filter"
            isChecked={statusFilter === 'INACTIVE'}
            labelText="Show inactive"
            isInline
            onChange={() => handleScenarioStatusFilterChange(statusFilter)}
          />
        }
        columns={SCENARIOS_TABLE_COLUMNS}
        data={scenariosTableData ?? []}
        handleAddNewClick={handleAddNewScenarioClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedScenarios({
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            shouldIncludeConditions: false,
            status: statusFilter,
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle={''}
        tableStats={
          {
            label: pluralize('scenario', totalScenarios),
            totalCount: totalScenarios,
          }}
        tableType="user scenarios"
        title="User scenarios"
        totalItems={totalScenarios}
      />

      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        tabs={tabs}
        hideSideBarButtons
      />
    </>
  );
};

export { UserScenariosTable };
