import { gql } from '@apollo/client';

import { SCENARIO_FRAGMENT } from '../../../fragments';

const UPDATE_SCHEDULE_DETAILS_MUTATION = gql`
  mutation UpdateScheduleDetails(
    $endDate: DateTime = null
    $maxRecurrence: Int!
    $scenarioId: Int!
    $shouldIncludeConditions: Boolean!
    $startDate: DateTime = null
  ) {
    scenarioMutations {
      updateDetails(endDate: $endDate, id: $scenarioId, maxRecurrence: $maxRecurrence, startDate: $startDate) {
        ...scenarioFragment
      }
    }
  }

  ${SCENARIO_FRAGMENT}
`;

export { UPDATE_SCHEDULE_DETAILS_MUTATION };
